interface ReportsIconProps {
  color?: string; // Optional prop for stroke color
}

const ReportsIcon: React.FC<ReportsIconProps> = ({ color }) => {
  const strokeColor = color || "white";

  const svgIcon = `
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.7273 4H6.27273C5.56983 4 5 4.58547 5 5.30769V19.6923C5 20.4145 5.56983 21 6.27273 21H17.7273C18.4302 21 19 20.4145 19 19.6923V5.30769C19 4.58547 18.4302 4 17.7273 4Z" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 17.0769H19" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.8182 7.26929H15.1818" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.8182 10.5386H15.1818" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.8182 13.8076H12.6364" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
    `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default ReportsIcon;
