import { useCallback } from 'react';
import dayjs from 'dayjs';

type UseDateDisablingProps = {
  allowedDatesFromPicker: any;
  allowedDatesToPicker?: Date[];
};

const useDateDisabling = ({ allowedDatesFromPicker, allowedDatesToPicker }: UseDateDisablingProps) => {
  const disabledDate = useCallback(
    (current: any) => {
      const currentDate = current.toDate(); // Convert dayjs object to Date
      return !allowedDatesFromPicker?.some((allowedFromDate: any) =>
        allowedFromDate.toDateString() === currentDate.toDateString() // Compare as strings
      );
    },
    [allowedDatesFromPicker]
  );

  const disabledToDate = useCallback(
    (current: any) => {
      const currentDate = dayjs(current); // Convert to dayjs object
      return !allowedDatesToPicker?.some((date: any) => dayjs(date).isSame(currentDate, 'day')); // Compare by day
    },
    [allowedDatesToPicker]
  );

  return {
    disabledDate,
    disabledToDate,
  };
};

export default useDateDisabling;
