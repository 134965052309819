import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>();

const getPaymentTypeMap = (t: any): Record<number, string> => ({
  1: t("paymentTypes.cash"),
  2: t("paymentTypes.pin"),
  3: t("paymentTypes.creditCard"),
  4: t("paymentTypes.gift"),
  5: t("paymentTypes.noPayment"),
  6: t("paymentTypes.payNL"),
  7: t("paymentTypes.onAccount"),
  8: t("paymentTypes.cancel"),
  9: t("paymentTypes.visa"),
  10: t("paymentTypes.mastercard"),
  11: t("paymentTypes.googlePay"),
  12: t("paymentTypes.applePay"),
  13: t("paymentTypes.oot"),
  14: t("paymentTypes.dinnerGiftCard"),
  15: t("paymentTypes.coinIntake"),
  16: t("paymentTypes.own"),
  "-100": t("paymentTypes.combined"),
});

export const getTableHeaders = (t: any) => {
  const paymentTypeMap = getPaymentTypeMap(t);

  return [
    columnHelper.accessor(
        row => `#${row.Id}`, 
        {
            id: 'Id',
            header: t("receiptReport.receipt"),
            cell: info => info.getValue()
        }),
    columnHelper.accessor(
      row => `${row.TableName} | ${row.TableSufix}`,
      {
        id: 'TableNameWithSuffix',
        header: t("receiptReport.table"),
        cell: info => info.getValue(),
      }
    ),
    columnHelper.accessor(
      row => paymentTypeMap[row.IdPaymentType] || t("paymentTypes.unknown"),
      {
        id: 'PaymentType',
        header: t("receiptReport.paymentType"),
        cell: info => info.getValue(),
      }
    ),
    columnHelper.accessor('BillBalance.TotalAmmount', {
      header: t("receiptReport.total"),
      cell: info => info.getValue().toFixed(2),
    }),
    // columnHelper.accessor('', {
    //   header: t("receiptReport.status"),
    // }),
    columnHelper.accessor('', {
      header: t("receiptReport.details"),
    }),
    ];
};


export const getDetailTableHeaders = (t: any) => {

  return [
    columnHelper.accessor('DateCreated', {
      header: t("receiptReport.timeOrdered"),
      cell: info => {
        const date = new Date(info.getValue());
        const formattedDate = date.toLocaleDateString('en-GB'); // Formats as DD/MM/YYYY
        const formattedTime = date.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false, // 24-hour format
        }); // Formats as HH:mm
        return `${formattedDate}\n${formattedTime}`;      },
    }),
    columnHelper.accessor('CreatedBy', {
      header: t("receiptReport.user"),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('Name', {
      header: t("receiptReport.products"),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('Quantity', {
      header: t("receiptReport.qty"),
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('Price', {
      header: t("receiptReport.price"),
      cell: info => info.getValue(),
    }),


    columnHelper.accessor('', {
      header: t("receiptReport.status"),
      cell: info => info.getValue(),
    }),
    ];
};
