const WhatsAppIcon = () => {
    const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clip-path="url(#clip0_2777_8242)">
      <path d="M1.01258 11.8563C1.01202 13.8728 1.54302 15.8417 2.5527 17.5771L0.916016 23.5066L7.03152 21.9156C8.72299 22.8292 10.6181 23.308 12.544 23.3081H12.5491C18.9067 23.3081 24.082 18.1748 24.0847 11.8653C24.086 8.80792 22.8871 5.93295 20.7089 3.76997C18.5311 1.60718 15.6347 0.415458 12.5486 0.414062C6.1902 0.414062 1.0153 5.54709 1.01267 11.8563" fill="url(#paint0_linear_2777_8242)"/>
      <path d="M0.600313 11.8527C0.599656 13.9417 1.14969 15.981 2.19537 17.7786L0.5 23.9207L6.83478 22.2726C8.58022 23.2168 10.5454 23.7147 12.5451 23.7154H12.5502C19.136 23.7154 24.4972 18.3975 24.5 11.8621C24.5011 8.69488 23.2591 5.71656 21.0031 3.47609C18.7468 1.23591 15.7468 0.00130233 12.5502 0C5.96337 0 0.602938 5.31721 0.600313 11.8527ZM4.37291 17.469L4.13637 17.0965C3.14206 15.5277 2.61725 13.7149 2.618 11.8534C2.62006 6.4213 7.07544 2.00186 12.554 2.00186C15.2071 2.00298 17.7005 3.02921 19.5759 4.89116C21.4512 6.7533 22.4831 9.22865 22.4824 11.8614C22.48 17.2935 18.0245 21.7135 12.5502 21.7135H12.5463C10.7638 21.7126 9.01569 21.2376 7.49113 20.34L7.12831 20.1265L3.36912 21.1045L4.37291 17.469Z" fill="url(#paint1_linear_2777_8242)"/>
      <path d="M9.56383 6.89746C9.34014 6.40416 9.10473 6.39421 8.89202 6.38556C8.71783 6.37811 8.5187 6.37867 8.31977 6.37867C8.12064 6.37867 7.79711 6.453 7.52364 6.74928C7.24989 7.04583 6.47852 7.76249 6.47852 9.22007C6.47852 10.6776 7.54848 12.0864 7.69764 12.2843C7.84698 12.4817 9.76323 15.5686 12.7981 16.7562C15.3204 17.743 15.8336 17.5468 16.381 17.4973C16.9285 17.448 18.1477 16.7808 18.3964 16.089C18.6453 15.3973 18.6453 14.8043 18.5707 14.6804C18.496 14.557 18.2969 14.4829 17.9983 14.3348C17.6997 14.1867 16.2317 13.4698 15.958 13.371C15.6843 13.2722 15.4853 13.2229 15.2861 13.5195C15.087 13.8157 14.5152 14.4829 14.341 14.6804C14.1669 14.8785 13.9926 14.9031 13.6941 14.755C13.3953 14.6063 12.4337 14.2938 11.2929 13.2846C10.4052 12.4993 9.80598 11.5296 9.6318 11.2329C9.45761 10.9367 9.61314 10.7762 9.76286 10.6285C9.89702 10.4958 10.0615 10.2826 10.211 10.1096C10.3599 9.93663 10.4095 9.81318 10.5091 9.6156C10.6088 9.41783 10.5589 9.24481 10.4844 9.09663C10.4095 8.94844 9.82933 7.48323 9.56383 6.89746Z" fill="white"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_2777_8242" x1="1159.35" y1="2309.67" x2="1159.35" y2="0.414062" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1FAF38"/>
        <stop offset="1" stop-color="#60D669"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2777_8242" x1="1200.5" y1="2392.07" x2="1200.5" y2="0" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F9F9F9"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <clipPath id="clip0_2777_8242">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>  
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default WhatsAppIcon;
  