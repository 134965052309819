const Weather5Icon = () => {
    const svgIcon = `
    <svg width="119" height="129" viewBox="0 0 396 378" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_di_958_2132)">
<path d="M362 114C362 174.751 312.751 224 252 224C191.249 224 142 174.751 142 114C142 53.2487 191.249 4 252 4C312.751 4 362 53.2487 362 114Z" fill="url(#paint0_linear_958_2132)"/>
</g>
<g filter="url(#filter1_b_958_2132)">
<g filter="url(#filter2_i_958_2132)">
<path d="M301.326 155.629C302.049 151.524 302.426 147.302 302.426 142.993C302.426 102.68 269.441 70 228.752 70C198.503 70 172.512 88.0612 161.166 113.893C151.974 106.03 140.047 101.283 127.012 101.283C97.949 101.283 74.3884 124.885 74.3884 154C74.3884 155.621 74.4615 157.225 74.6045 158.809C60.6255 165.593 51 179.828 51 196.29C51 219.326 69.8484 238 93.0992 238H291.901C315.152 238 334 219.326 334 196.29C334 176.464 320.039 159.869 301.326 155.629Z" fill="url(#paint1_linear_958_2132)"/>
</g>
</g>
<g filter="url(#filter3_di_958_2132)">
<path d="M125.5 266C113.049 282.333 95.6169 315 125.5 315C155.383 315 137.951 282.333 125.5 266Z" fill="#00BCFF"/>
</g>
<g filter="url(#filter4_di_958_2132)">
<path d="M195.5 290C183.049 306.333 165.617 339 195.5 339C225.383 339 207.951 306.333 195.5 290Z" fill="#00BCFF"/>
</g>
<g filter="url(#filter5_di_958_2132)">
<path d="M260.5 266C248.049 282.333 230.617 315 260.5 315C290.383 315 272.951 282.333 260.5 266Z" fill="#00BCFF"/>
</g>
<g filter="url(#filter6_f_958_2132)">
<rect x="78" y="233" width="240" height="35" rx="17.5" fill="#00BCFF"/>
</g>
<defs>
<filter id="filter0_di_958_2132" x="109" y="0" width="286" height="286" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="29"/>
<feGaussianBlur stdDeviation="16.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.283997 0 0 0 0 0.1825 0 0 0 0 0.9125 0 0 0 0.68 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_958_2132"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_958_2132" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="5"/>
<feGaussianBlur stdDeviation="9"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.81 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_958_2132"/>
</filter>
<filter id="filter1_b_958_2132" x="24" y="43" width="337" height="222" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="13.5"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_958_2132"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_958_2132" result="shape"/>
</filter>
<filter id="filter2_i_958_2132" x="51" y="70" width="283" height="178" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="11"/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_958_2132"/>
</filter>
<filter id="filter3_di_958_2132" x="90" y="266" width="71" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="9.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.283785 0 0 0 0 0.178889 0 0 0 0 0.933333 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_958_2132"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_958_2132" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="9"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_958_2132"/>
</filter>
<filter id="filter4_di_958_2132" x="160" y="290" width="71" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="9.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.283785 0 0 0 0 0.178889 0 0 0 0 0.933333 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_958_2132"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_958_2132" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="9"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_958_2132"/>
</filter>
<filter id="filter5_di_958_2132" x="225" y="266" width="71" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="20"/>
<feGaussianBlur stdDeviation="9.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.283785 0 0 0 0 0.178889 0 0 0 0 0.933333 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_958_2132"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_958_2132" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="9"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_958_2132"/>
</filter>
<filter id="filter6_f_958_2132" x="0" y="155" width="396" height="191" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="39" result="effect1_foregroundBlur_958_2132"/>
</filter>
<linearGradient id="paint0_linear_958_2132" x1="235.291" y1="173.873" x2="325.797" y2="15.1392" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF9900"/>
<stop offset="1" stop-color="#FFEE94"/>
</linearGradient>
<linearGradient id="paint1_linear_958_2132" x1="69" y1="223" x2="354.5" y2="9.49997" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0.58"/>
</linearGradient>
</defs>
</svg>

        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default Weather5Icon;
  

