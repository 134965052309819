import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>()

export const getdaysTH = (t: any) => [
    columnHelper.accessor('VatName', {
      header: t("percentageReport.taxName")
    }),
    columnHelper.accessor('Turnover', {
      header: t("percentageReport.turnover")
    }),
    columnHelper.accessor('VAT', {
      header: t("percentageReport.vat")
    }),
  ]

  export const getDiscountsTH = (t: any) => [
    columnHelper.accessor('Datum', {
      header: t("percentageReport.date")
    }),
    columnHelper.accessor('TableShortDescription', {
      header: t("percentageReport.table")
    }),
    columnHelper.accessor('DateTimeDiscount', {
      header: t("percentageReport.time")
    }),
    columnHelper.accessor('DiscountText', {
      header: t("percentageReport.disc")
    }),
    columnHelper.accessor('DiscountAmount', {
      header: t("percentageReport.amt")
    }),
  ]
  
  export const getProductsTH = (t: any) => [
    columnHelper.accessor('MainCategory', {
      header: t("percentageReport.categories"),
    }),
    columnHelper.accessor('Quantity', {
      header: t("percentageReport.qty"),
    }),
    columnHelper.accessor('SubTotal', {
      header: t("percentageReport.amount"),
      cell: (info) => {
        const value = info.getValue();
        return value === 0 ? `${value}` : typeof value === 'number' ? `${value.toFixed(2)}` : `${value}`;
      }
    }),
  ];