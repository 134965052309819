interface ArrowDownIconProps {
  color?: string; // Optional prop for stroke color
  h?: string;
  w?: string;
}

const ArrowDownIcon: React.FC<ArrowDownIconProps> = ({color, w, h}) => {

  const strokeColor = color || "#898989";
  const customW = w || "24";
  const customH = h || "24";

  const svgIcon = `
  <svg width=${customW} height=${customH} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 10L12 14L16 10" stroke=${strokeColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>  
    `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default ArrowDownIcon;
