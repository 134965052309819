interface XIconProps {
    color?: string; // Optional prop for stroke color
    h?: string;
    w?: string;
  }
const XIcon: React.FC<XIconProps> = ({color, w, h}) => {
    const customW = w || "24";
    const customH = h || "24";
    const svgIcon = `
    <svg width=${customW} height=${customH} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.17017 14.83L14.8302 9.17004" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8302 14.83L9.17017 9.17004" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.00024 22H15.0002C20.0002 22 22.0002 20 22.0002 15V9C22.0002 4 20.0002 2 15.0002 2H9.00024C4.00024 2 2.00024 4 2.00024 9V15C2.00024 20 4.00024 22 9.00024 22Z" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>        
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default XIcon;
  