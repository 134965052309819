interface FilterIconProps {
  color?: string; // Optional prop for stroke color
  h?: string;
  w?: string;
}
const FilterIcon: React.FC<FilterIconProps> = ({color, w, h}) => {
  const strokeColor = color || "#898989";
  const customW = w || "24";
  const customH = h || "24";

  const svgIcon = `
  <svg width=${customW} height=${customH} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 7H21" stroke="#898989" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M6 12H18" stroke="#898989" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M10 17H14" stroke="#898989" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default FilterIcon;
