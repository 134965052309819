import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import { ReceiptProps } from "./ReceiptReport.type";
import { useTranslation } from "react-i18next";
import Table from "../common/table/Table";
import { getDetailsTableOptions, getTableOptions } from "./ReciptReportTableOptions";
import { getDetailTableHeaders, getTableHeaders } from "./ReciptReportTableConfig";
import ReceiptReportFilters from "../receiptReportFilters/ReceiptReportFilters";
import useDateFormatter from "../../hooks/useDateFormatter";


const ReceiptReports: React.FC<ReceiptProps> = () => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const {t} = useTranslation();
  const [exportType, setExportType] = useState<any>("0");
  const [fileUrl, setFileUrl] = useState<null | string>(null);
  const menuId = localStorage.getItem("MI")
  const [data, setData] = useState<any>([])
  const [receiptId, setReceiptId] = useState<any>(null);
  const [detailsOn, setDetailsOn] = useState<boolean>(false);
  const [paymentsDetails, setPaymentsDetails] = useState<any>([])
  const { formatDateSP } = useDateFormatter();
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(new Date().setDate(new Date().getDate() - 1))));

  const handleTableUpdate = (newReceiptId: any, newDetailsOn: boolean) => {
    setReceiptId(newReceiptId);
    setDetailsOn(newDetailsOn);
  };

  const getBEData = async () =>  {
    if (location.pathname === "/reports/receiptReport" && menuId && fromDate) {
          const requestBody = {
            IdMenu: menuId,
            TargetDate: fromDate,
            Language: i18n.language,
            ExportType: exportType,
        }
          setIsLoadingLiveData(true);

          try {
            const apiResponse = await fetchData(API_ROUTES.getReceiptReport, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            if(apiResponse.data.Receipts) {
              setData(apiResponse.data.Receipts)
            } else {
              setData([])
            }
            if(apiResponse.data.DocumentUrl){
              setFileUrl(apiResponse.data.DocumentUrl.FileName)
            } else {
              setFileUrl(null)
            }
            setDetailsOn(false)
          } catch (error) {
            console.log(error)
            setData([])
            // console.error("Error fetching data:", error);
          } finally {
            setIsLoadingLiveData(false);
          }
    }
  };

const handleExportChange = (exportType: any) => {
  setExportType(exportType[0])
}

const [detailsData, setDetailsData] = useState<any>([])
const [dataById, setDataById] = useState<any[]>()
useEffect(() => {
  if(receiptId !== undefined) {
    const dataById = data.find((item: any) => item.Id === receiptId)
    setDetailsData(dataById ? dataById.ReceiptDetails : [])
    setDataById(dataById)
    setPaymentsDetails(dataById ? dataById.PaymentDetails : [])
  }
}, [receiptId, detailsOn, data])
const [filter, setFilter] = useState<any>()
  const handleFilterUpdate = (selectedFilter: any) => {
    setFilter(selectedFilter[0])
};

const filteredData = data?.filter((item: any) => {
  if (filter == 'all') {
    return true;
  }
  return item.IdPaymentType == filter;
});

const tableTH = getTableHeaders(t);
const detailsTableTH = getDetailTableHeaders(t);
const options = getTableOptions(filteredData, tableTH);
const detailsData1 = detailsData.map((item: any) => {
  if ([-10, -1, -3, -4, -8, -9].includes(item.Round)) {
    const updatedItem = { ...item, Quantity: "" };
    if ([-10, -1, -3, -4].includes(item.Round)) {
      updatedItem.Price = "";
    }
    return updatedItem;
  }
  return item;
});

const detailsTableOptions = getDetailsTableOptions(detailsData1, detailsTableTH)

let totalPaymentsAmount;
totalPaymentsAmount = paymentsDetails.length > 0 
  ? paymentsDetails.reduce((sum: number, item: any) => 
      item.Active === true ? sum + item.AmountPaid : sum
    , 0)
  : 0;


  const handleDate = (date: any) => {
    setFromDate(date)
  }
  const handleMenuId = (menuId: any) => {
    localStorage.setItem("MI", menuId)
  }
  const handleFetchData = () => {
    getBEData();
  }

  
return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto md:pt-4 pb-16 min-h-screen ">
      <ReceiptReportFilters sendDateToParent={handleDate} sendMenuIdToParent={handleMenuId} fetchReportData={handleFetchData} fileUrl={fileUrl} handleExportChange={handleExportChange}/>
      
      <div className="relative pt-[18rem] md:pt-56" style={{ maxHeight: "95vh", overflowY: "auto" }}>
    {!detailsOn && data.length > 0 &&
      <Table options={options} onUpdate={handleTableUpdate} detailsOn={detailsOn} onFiltersUpdate={handleFilterUpdate} isAllowedFiltering={true} fromDate={fromDate}/>
    }
    {detailsOn && detailsData.length > 0 &&
      <Table options={detailsTableOptions} dataById={dataById} detailsOn={detailsOn} onUpdate={handleTableUpdate} isDetailTable={true} />
    }
  </div>
<ScrollToTopButton />

    </Wrapper>
  );
};

export default ReceiptReports;
