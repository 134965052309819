import React from "react";

const Loader: React.FC = () => {
  return (
    <div
      className="fixed top-0 left-0
      
      
      w-screen h-screen flex items-center justify-center"
      style={{ zIndex: 999999999, backgroundColor: "rgba(255, 255, 255, 0.8)" }}
    >
      <div className="animate-spin ">
        <img src="/loader.png" alt="" />
      </div>
    </div>
  );
};

export default Loader;
