interface LogOutIconProps {
  color?: string; // Optional prop for stroke color
}

const LogOutIcon: React.FC<LogOutIconProps> = ({ color }) => {
  const strokeColor = color || "white";

  const svgIcon = `
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.23077 8.42308V5.80769C8.23077 5.46089 8.36855 5.12822 8.61378 4.88302C8.85902 4.63783 9.19164 4.5 9.53846 4.5H18.6923C19.0391 4.5 19.3717 4.63783 19.617 4.88302C19.8622 5.12822 20 5.46089 20 5.80769V20.1923C20 20.5391 19.8622 20.8717 19.617 21.117C19.3717 21.3622 19.0391 21.5 18.6923 21.5H9.53846C9.19164 21.5 8.85902 21.3622 8.61378 21.117C8.36855 20.8717 8.23077 20.5391 8.23077 20.1923V17.5769" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.1543 13H3.00045" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5.6153 15.6155L2.99991 13.0001L5.6153 10.3847" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  
    
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default LogOutIcon;
