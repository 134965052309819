const HomeIcon = () => {
    const svgIcon = `
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.52 2.84016L4.13 7.04016C3.23 7.74016 2.5 9.23016 2.5 10.3602V17.7702C2.5 20.0902 4.39 21.9902 6.71 21.9902H18.29C20.61 21.9902 22.5 20.0902 22.5 17.7802V10.5002C22.5 9.29016 21.69 7.74016 20.7 7.05016L14.52 2.72016C13.12 1.74016 10.87 1.79016 9.52 2.84016Z" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5 17.9902V14.9902" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> 
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default HomeIcon;
  