import Wrapper from "../common/wrapper/Wrapper";
import { useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { LoadingLiveDataContext } from "../../context/isLoadingLiveDataContext";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Correction, DailyTotals, Details, Discount, OrderTotals, Orders, OutOfTurnover, OwnUse, PaymentType, TaxInterfaces, TaxParentGroupDetails, ZProps } from "./ZReport.type";
import ScrollToTopButton from "../common/scrollToTopButton/ScrolToTopButton";
import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import Totals from "./TotalsComponent";
import TaxParentGroupComponent from "./TaxParentGroupComponent";
import ExcludedVatComponent from "./ExcludedVatComponent";
import TaxComponent from "./TaxComponent";
import TurnoverComponent from "./TurnoverComponent";
import PaymentComponent from "./PaymentComponent";
import DailyTotalsComponent from "./DailyTotalsComponent";
import OtherComponent from "./OtherComponent";
import ZreportFilter from "../zreportFilters/ZreportFilters";

const ZReports: React.FC<ZProps> = () => {
  const { setIsLoadingLiveData } = useContext(LoadingLiveDataContext);
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [paymentData, setPaymentData] = useState<PaymentType[]>([]);
  const [dailyTotals, setDailyTotals] = useState<DailyTotals[]>([]);
  const [taxExcludeVat, setTaxExcludeVat] = useState<TaxInterfaces[]>([]);
  const [tax, setTax] = useState<TaxInterfaces[]>([]);
  const [turnover, setTurnover] = useState<TaxInterfaces[]>([]);
  const [discount, setDiscount] = useState<Discount[]>([]);
  const [corrections, setCorecctions] = useState<Correction[]>([]);
  const [ownUse, setOwnUse] = useState<OwnUse[]>([]);
  const [ordersTotals, setOrdersTotals] = useState<OrderTotals[]>([]);
  const [outOfTurnover, setOutOfTurnover] = useState<OutOfTurnover[]>([]);
  const [taxParentGroupDetails, setTaxParentGroupDetails] = useState<TaxParentGroupDetails[]>([]);
  const [orders, setOrders] = useState<Orders[]>([])
  const {t} = useTranslation();
  const isTaxParentGroup = JSON.parse(localStorage.getItem("toggle_1") || "false");
  const test = localStorage.getItem("menuId");
  const parsedTest = test && JSON.parse(test);
  const option = parsedTest ? { value: parsedTest.IdMenu, label: parsedTest.MenuName } : null;
  const [date, setDate] = useState<any>();

  const menuId = localStorage.getItem("MI")

  useEffect(() => {
    if (
      location.pathname === "/reports/zreport"
    ) {
      if (menuId && date) {
        const getData = async () => {
          const requestBody = {
            Language: i18n.language,
            IdMenu: option && option.value,
            TargetDate: date
          };
          
          // setIsLoadingLiveData(true);
          
          try {
            const apiResponse = await fetchData(API_ROUTES.getZreport, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            setPaymentData(apiResponse.data.Payments);
            setDailyTotals(apiResponse.data.DailyTotal);
            setTaxExcludeVat(apiResponse.data.TaxExcludeVat);
            setTax(apiResponse.data.TaxVat);
            setTurnover(apiResponse.data.Turnover);
            setDiscount(apiResponse.data.Disocunts);
            setCorecctions(apiResponse.data.Corrections);
            setOwnUse(apiResponse.data.OwnUse);
            setOrdersTotals(apiResponse.data.OrdersTotal);
            setOutOfTurnover(apiResponse.data.OutOfTurnover)
            setTaxParentGroupDetails(groupByTaxType(apiResponse.data.TaxParentGroupDetails));
            setOrders(apiResponse.data.Orders)
          } catch (error) {
            setPaymentData([]);
            setDailyTotals([]);
            setTaxExcludeVat([]);
            setTax([]);
            setTurnover([]);
            setDiscount([]);
            setCorecctions([]);
            setOwnUse([]);
            setOrdersTotals([]);
            setOutOfTurnover([]);
            setTaxParentGroupDetails([]);
            setOrders([])
          } finally {
            setIsLoadingLiveData(false);
          }
        };

        getData();
      }
    }
  }, [menuId, location.pathname, date]);

const groupByTaxType = (details: Details[]) => {
  return details.reduce((groups: any, item: any) => {
    if (!groups[item.TaxType]) {
      groups[item.TaxType] = [];
    }
    groups[item.TaxType].push(item);
    return groups;
  }, []);
};

const isMobile = useIsMobileDevice();
const handleDate = (date: any) => {
 setDate(date)
}
  return (
    <Wrapper onClick={() => {}} classes="w-[95%] mx-auto pt-4 pb-16">
            <ZreportFilter sendDateToParent={handleDate}/>
            <div className="relative pt-[16rem] md:pt-[12rem] " style={{ maxHeight: "95vh", overflowY: "auto" }}>

      {orders.length > 0 && isMobile ? (
        // mobile
      <div className="w-full grid grid-cols-1 md:grid-cols-2 ">
      {ordersTotals[0] ? ( 
        <>
        <p className="text-base md:text-xl text-custom-textBlackColor">
        {t("zreport.totals")}
        </p>
        <Totals ordersTotals={ordersTotals}/>
        </>
    ) : (<></>)}
 
          {taxExcludeVat.length > 0 ? ( 
            <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.excludedVAT")}
            </p> 
            <ExcludedVatComponent taxExcludeVat={taxExcludeVat}/>
            </>
        ) : (<></>)}

       
          {tax.length > 0 ? ( 
            <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.tax")}
            </p>  
            <TaxComponent tax={tax}/>
            </>      
        ) : (<></>)}

   
          {turnover.length > 0 ? (     
            <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.turnover")}
            </p>  
            <TurnoverComponent turnover={turnover}/>
            </>   
        ) : (<></>)}

{isTaxParentGroup && taxParentGroupDetails.length > 0 ? 
     <>
      <p className="text-base md:text-xl text-custom-textBlackColor">
        {t("zreport.parentTaxGroup")}
      </p>
     <TaxParentGroupComponent taxParentGroupDetails={taxParentGroupDetails}/>
     </>
      :
    <></>}

            {paymentData.length > 0 && dailyTotals ? (         
              <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.payment")}
            </p> 
              <PaymentComponent paymentData={paymentData}/>
              </>  
      ) : (<></>)}

        {dailyTotals.length > 0 ? ( 
          <>
             <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.dailyTotals")}
            </p>
          <DailyTotalsComponent dailyTotals={dailyTotals}/>
          </>
      ) : (<></>)}
  
        {ownUse.length > 0 ? (
        <>
          <p className="text-base md:text-xl text-custom-textBlackColor">
          {t("zreport.other")}
          </p>
          <OtherComponent corrections={corrections} discount={discount} outOfTurnover={outOfTurnover} ownUse={ownUse} isByStatus={true}/>
        </>
      ) : (<></>)}

          {outOfTurnover ? ( 
            <>
                  <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.other")}
            </p>
                   <OtherComponent corrections={corrections} discount={discount} outOfTurnover={outOfTurnover} ownUse={ownUse}/>
            </>
      ) : (<></>)}
       

        </div>) : orders.length > 0 && !isMobile ? (
        // desktop/////////////////////////////////////////////////////////////////////////////////////
        <>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
          {ordersTotals[0] ? ( 
            <>
            <p className="text-base md:text-xl text-custom-textBlackColor">
            {t("zreport.totals")}
            </p>
            <Totals ordersTotals={ordersTotals}/>
            </>
    ) : (<></>)}

      {paymentData.length > 0 && dailyTotals ? (       
                   <>
                   <p className="text-base md:text-xl text-custom-textBlackColor">
                   {t("zreport.payment")}
                   </p> 
                     <PaymentComponent paymentData={paymentData}/>
                     </>   
      ) : (<></>)}
  
        {outOfTurnover ? ( 
          <>         
          <p className="text-base md:text-xl text-custom-textBlackColor">
          {t("zreport.other")}
          </p>
          <OtherComponent corrections={corrections} discount={discount} outOfTurnover={outOfTurnover} ownUse={ownUse}/>
        </>
      ) : (<></>)}

        {ownUse.length > 0 ? (
          <>
                   <p className="text-base md:text-xl text-custom-textBlackColor">
                   {t("zreport.other")}
                   </p>
                   <OtherComponent corrections={corrections} discount={discount} outOfTurnover={outOfTurnover} ownUse={ownUse} isByStatus={true}/>
                 </>
      ) : (<></>)}
        

          </div>
          <div className="">

        {taxParentGroupDetails.length > 0 ? (<div className="">

          {taxParentGroupDetails.length > 0 ? (    
                       <>
                       <p className="text-base md:text-xl text-custom-textBlackColor">
                       {t("zreport.excludedVAT")}
                       </p> 
                       <ExcludedVatComponent taxExcludeVat={taxExcludeVat}/>
                       </>    
        ) : (<></>)}

          {taxParentGroupDetails.length > 0 ? (   
                    <>
                    <p className="text-base md:text-xl text-custom-textBlackColor">
                    {t("zreport.tax")}
                    </p>  
                    <TaxComponent tax={tax}/>
                    </>     
        ) : (<></>)}

          {taxParentGroupDetails.length > 0 ? (     
                        <>
                        <p className="text-base md:text-xl text-custom-textBlackColor">
                        {t("zreport.turnover")}
                        </p>  
                        <TurnoverComponent turnover={turnover}/>
                        </>    
        ) : (<></>)}
            </div>) : (<></>)}
            
            {isTaxParentGroup ?      
              <>
              <p className="text-base md:text-xl text-custom-textBlackColor">
                {t("zreport.parentTaxGroup")}
              </p>
             <TaxParentGroupComponent taxParentGroupDetails={taxParentGroupDetails} />
             </>
            : <></>}
          
        {dailyTotals.length > 0  ? ( 
                  <>
                  <p className="text-base md:text-xl text-custom-textBlackColor">
                 {t("zreport.dailyTotals")}
                 </p>
               <DailyTotalsComponent dailyTotals={dailyTotals}/>
               </>
      ) : (<></>)}
        </div>
        </div> 
        <div>       
        </div>
        </>
      ) : (
      <><div className="text-gray-500 pt-12 h-screen">{t("errorMessages.noDataToBeShown")}</div></>) }
</div>
<ScrollToTopButton />

    </Wrapper>
  );
};

export default ZReports;
