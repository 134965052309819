import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import ZReportRow from "../zReportRow/ZReportRow";
import { v4 as uuidv4 } from 'uuid';

interface Props {
    taxParentGroupDetails: any;
}

const getSum = (items: any) => {
    return items.reduce((total: any, item: any) => total + item.Ammount, 0);
  }

const TaxParentGroupComponent: React.FC<Props> = ({taxParentGroupDetails}) => {
    const { t } = useTranslation();

  return (
    <Wrapper classes={`${taxParentGroupDetails.length > 0 ? " rounded-lg mb-4" : ""}`} onClick={() => {}}>
        <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 shadow-lg">
        {taxParentGroupDetails.length > 0 ? taxParentGroupDetails[1].map((item: any) => {return(<ZReportRow key={uuidv4()}  title={item.VatName} value={item.Ammount.toFixed(2)} />)}) : <></>}
        <ZReportRow title={t("periodReport.total")} value={getSum(taxParentGroupDetails[1]).toFixed(2)} color="bg-custom-bgPink"  classes="mb-4" fontSize="text-base"/>

        {taxParentGroupDetails.length > 0 ? taxParentGroupDetails[2].map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={item.Ammount.toFixed(2)}/>)}) : <></>}
        <ZReportRow title={t("periodReport.total")} value={getSum(taxParentGroupDetails[2]).toFixed(2)} color="bg-custom-bgPink"  classes=" mb-4" fontSize="text-base"/>

        {taxParentGroupDetails.length > 0 ? taxParentGroupDetails[3].map((item: any) => {return(<ZReportRow key={uuidv4()} title={item.VatName} value={item.Ammount.toFixed(2)}/>)}) : <></>}
        <ZReportRow title={t("periodReport.total")} value={getSum(taxParentGroupDetails[3]).toFixed(2)} color="bg-custom-bgPink"  classes=" " fontSize="text-base"/>

        </Wrapper>
    </Wrapper> 
  );
};

export default TaxParentGroupComponent;
