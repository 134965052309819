import { useTranslation } from "react-i18next";
import { flexRender, useReactTable } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { TableProps } from "./Table.type";
import Wrapper from "../wrapper/Wrapper";
import useIsMobileDevice from "../../../hooks/useIsMobileDevice";
import ExclamationMarkIcon from "../../icons/ExclamationMarkIcon";
import NoPaymentIcon from "../../icons/NoPaymentIcon";
import FilterPopover from "./FilterPopover";
import XIcon from "../../icons/XIcon";
import dayjs from "dayjs";
import usePaymentTypeMap from "../../../hooks/usePaymentTypeMap";
import ExportReceipt from "../../receiptReports/ExportReceipt";
import Dropdown from "../dropdown/Dropdown";

const Table: React.FC<TableProps> = ({
  options,
  onUpdate,
  detailsOn,
  dataById,
  onFiltersUpdate,
  isDetailTable = false,
  isAllowedFiltering = false,
  fromDate,
}) => {
  const { t } = useTranslation();

  const isMobile = useIsMobileDevice();
  const [detailsOnn, setDetailsOn] = useState<boolean>(detailsOn ? detailsOn : false);

  const table = useReactTable<any>(options);
  const [receiptId, setReceiptId] = useState<any>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]); // Track selected rows
  const numbersString = Array.from(selectedRows).join(',');

  useEffect(() => {
    setSelectedRows([])
  },[fromDate])
  
  useEffect(() => {
    if(onUpdate){
        onUpdate(receiptId, detailsOnn)
    }
  }, [receiptId, detailsOnn])

  const handleRowClick = (id: number) => {
    if(!detailsOn){

        const newDetailsOn = !detailsOn;
        setDetailsOn(newDetailsOn);
        setReceiptId(id)
    }
  };

  const handleCloseDetails = () => {
    setDetailsOn(false);
    setReceiptId(null)
    if (onUpdate) {
        onUpdate(receiptId, false);
      }
  }

  const handleSelectRow = (id: number) => {
    setSelectedRows((prevSelectedRows: any) => {
      const newSelectedRows = Array.isArray(prevSelectedRows) ? [...prevSelectedRows] : [];

      const index = newSelectedRows.indexOf(id);
      if (index > -1) {
        newSelectedRows.splice(index, 1);
      } else {
        newSelectedRows.push(id);
      }
  
      return newSelectedRows;
    });

  };
  
  
  const handleSelectAll = () => {
    if (selectedRows?.length === options.data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(options.data.map((row: any) => row.Id));
    }
  };
  

  const filterOptions = [
    { value: 'all', label: t("receiptReport.allReceipts") },
    { value: '5', label: t("receiptReport.noPayment") },
  ]

  const handleFilterUpdate = (selectedFilter: any) => {
    if(onFiltersUpdate){
        onFiltersUpdate([selectedFilter])
    }
    setSelectedRows([])
};
const { getPaymentTypeName } = usePaymentTypeMap();

const statusLegend = [
  {short: "C", long: "Correction"},
  {short: "T", long: "Transfer "},
  {short: "Own", long: "Own use "},
  {short: "H", long: "From house "},
  {short: "CF", long: "Correction - possible Fraud"}
]
const exportTypeOptions = [
  { value: '0', label: t("receiptReport.audit") },
  { value: '1', label: t("receiptReport.edited") },
  { value: '2', label: t("receiptReport.viewed") },
]
  return (
    <Wrapper onClick={() => {}} classes="max-w-full text-xs md:text-base overflow-auto mt-16">
      {isAllowedFiltering && 
          <FilterPopover options={filterOptions} onFilterChange={handleFilterUpdate}/>
      }
        
      <div className="rounded-[10px] shadow-custom4 overflor-x-auto max-w-full">
      {detailsOnn && dataById &&
        <div className="bg-custom-tableBgGreen flex justify-between items-center py-2 px-4 rounded-t-lg ">
          <div className="text-xxs md:text-[15px] flex gap-4 items-center">
          <span>#{dataById.Id}</span>
          <span>{t("receiptReport.table")}: {dataById.TableName}|{dataById.TableSufix}</span>
            <span>{t("receiptReport.openedAt")}: {dayjs(dataById.TimeActivation).format("DD/MM/YYYY")} <br /> {dayjs(dataById.TimeActivation).format("HH:mm:ss")}</span>
          <span>{t("receiptReport.closedAt")}: {dayjs(dataById.TimeDeActivation).format("DD/MM/YYYY")} <br /> {dayjs(dataById.TimeDeActivation).format("HH:mm:ss")}</span>
          <span>{t("receiptReport.paymentType")}: {(getPaymentTypeName(Number(dataById.IdPaymentType)))}</span>
          </div>
          <button onClick={handleCloseDetails} className="text-red-600 font-bold">
            <XIcon w={isMobile ? "16px" : ""} h={isMobile ? "16px" : ""}/>
          </button>
        </div>
      }
            <div className={`overflow-x-auto ${!isDetailTable ? "rounded-[8px]" : "rounded-b-[8px]"}`}>

        <table className={`w-full text-left overflow-auto box-border ${!isDetailTable ? "rounded-[10px]" : "rounded-b-[10px]"}`}>
          <thead className={`border-b border-custom-bgLightPurple ${!isDetailTable && "rounded-[10px]"} bg-custom-tableHeadBg `}>
            
            {table.getHeaderGroups().map((headerGroup) => (
                <tr
                key={headerGroup.id}
                className="min-w-1/2"
                style={{
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    overflow: "hidden",
                }}
                >
                {headerGroup.headers.map((header) => (
                    <th
                    key={header.id}
                    className=" font-medium py-3 md:py-4 pl-2 md:pl-4 border-b border-custom-bgLightPurple md:border-b-0"
                    >
                      {header.index === 0 && !detailsOn &&         
                    <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows?.length === options.data.length}
                    className="mr-1"
                  />}
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="max-w-full">
            {table.getRowModel().rows.map((row, index) => {
                const data: any = row.original;
                const isTotal = data.VatName === "Total"
                const isNoPayment = data.IdPaymentType == 5;
                const isPotentialFraud = detailsOn ? data.ReceiptDetails?.some((item: any) => item.IsPotentialFraud) : data.IsPotentialFraud;
                const modifiers = (data.Round > 0 || data.Round === -8 || data.Round === -9) && data.Modifiers !== null && data.Modifiers[0].Title !== null ? data.Modifiers : null;
                const isSelected = selectedRows?.some((item: any) => item === data.Id);
                
                  const getStatus = (data: any) => {
                  const status = [];
                
                  switch (data.IdStatusType) {
                    case 2:
                      status.push("H");
                      break;
                    case 1:
                      status.push("Own");
                      break;
                    default:
                      break;
                  }
                
                  if (data.IdTransfer > 0) {
                    status.push("T");
                  }
                  if (data.Quantity < 0) {
                    status.push("C");
                  }
                  if (data.IsPotentialFraud) {
                    status.push("CF");
                  }
                
                  // console.log(status)
                  return status;
                };
                const status = getStatus(data);
                        //  console.log(status.map((st: any) => st))
                            return (
                                <tr
                                id={`row-${data.Id}`}
                                // onClick={() => handleRowClick(data.Id)}
                                key={row.id}
                                className={`${index % 2 !== 0 ? "" : ""} ${isTotal ? "bg-custom-tableBgPurple" : ""} ${isPotentialFraud || isNoPayment ? "bg-custom-potentialFraudBg" : ""} ${status.includes("CF") ?  "bg-custom-potentialFraudBg" : ""}`}
                                style={{
                                    backgroundColor: index % 2 !== 0 ? "#F3F3F3" : "white",
                                }}
                                >
                                  
                    {row.getVisibleCells().map((cell: any, cellIndex: number) => (
                        <td key={cell.id} id={cell.id} onClick={() => {}} className={`py-3 md:py-4 pl-2 md:pl-4 mr-2  ${cellIndex === row.getVisibleCells().length - 1 && "max-w-24 "} ${cellIndex === 0 && !detailsOn && "flex"}  ${modifiers?.length > 0 ? "py-[4px]" : ""} ${cellIndex === 0 ? "" : ""}`}>
                          {cellIndex === 0 && !detailsOn && <input
                          className="mr-1"
            type="checkbox"
            checked={isSelected}
            onChange={() => {
              handleSelectRow(data.Id);
            }}
          />}
            {cellIndex === 0 && isPotentialFraud || cellIndex === 0 && isNoPayment || cellIndex === 2 && status.includes("CF") 
              ? (<span className="flex items-center gap-1">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
             <ExclamationMarkIcon />
             {modifiers?.length > 0 && cellIndex === 0 && (
                 <ul className="max-w-full">
        {modifiers.map((modifierGroup: any, groupIndex: number) => (
        <li key={groupIndex}>
        {/* Display the title */}
        <span className="text-sm mr-[1px]">{modifierGroup.Title}</span>
        {/* Display the modifiers for each group */}
        {modifierGroup.Modifier.map((modifier: any, modifierIndex: number) => (
            <span key={modifierIndex} className="text-xs text-gray-500 mr-[1px]">
            {modifier.Name};
          </span>
        ))}
      </li>
    ))}
  </ul>
)}
            </span>)
              : typeof cell.getValue() === 'number'
              ? cell.getValue()
              : <span>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {isPotentialFraud &&  <ExclamationMarkIcon />}
                    {modifiers?.length > 0 && cellIndex === 2 && (
                        <ul className="max-w-full">
    {modifiers.map((modifierGroup: any, groupIndex: number) => {
      return(
        <li key={groupIndex}>
        {/* Display the title */}
        <span className="text-sm mr-[1px]">
          {modifierGroup.Title !== "Inactive payment" && modifierGroup.Title !== "Payment" ? modifierGroup.Title : ""}
        </span>
        {/* Display the modifiers for each group */}
        {modifierGroup.Modifier.map((modifier: any, modifierIndex: number) => (
          <span key={modifierIndex} className="text-xs text-gray-500 mr-[1px]">
           {modifierGroup.Title === "Payment" || modifierGroup.Title === "Inactive payment"
            ? (getPaymentTypeName(Number(modifier.Name)) + " " + modifier.Price)
            : modifier.Name}
          
          </span>
        ))}
        <br></br>
      </li>
      )
            })}
  </ul>
)}

                </span>
                }

                  {cellIndex === row.getVisibleCells().length - 1 && (
          
                  // detailsOn && status.length > 0 ? <></> : <p>{status.map((st: any) => st)}</p>
                  status.map((value, index) => (
                    <span className="bg-custom-statusBg p-0.5 rounded-[2px] mr-1" key={index}>{value}</span>
                  ))
    )}
        {/* {cellIndex === row.getVisibleCells().length - 1 && !detailsOn && <span className="flex justify-around items-center "><Dropdown isReceiptTable={true} isMultiSelect={false} onChange={() => {}} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="w-20 border-none"/> <button onClick={() => {handleRowClick(data.Id)}} className="bg-custom-bgBlue text-white rounded-lg px-4 ">{isMobile ? t("receiptReport.details") : t("receiptReport.seeMore")}</button></span>} */}
        {cellIndex === row.getVisibleCells().length - 1 && !detailsOn &&<button onClick={() => {handleRowClick(data.Id)}} className="bg-custom-bgBlue text-white rounded-lg px-4">{isMobile ? t("receiptReport.details") : t("receiptReport.seeMore")}</button>}

          </td>
        ))}
                </tr>
              );
            }
            
        )}
          </tbody>
        </table>
      </div>
      </div>
    {!detailsOn &&
      <div className={`text-[8px] md:text-xs flex justify-between items-center w-[97%] mx-auto mt-2`}>
  <span className="flex items-center gap-2">
    {t("periodReport.showing")}
    <select
      className="border rounded-lg px-2 py-2"
      value={table.getState().pagination.pageSize}
      onChange={e => {
        table.setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 30, 40, 50].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      ))}
    </select>
    <p style={{ color: "#5A5A5A" }}>
      {t("periodReport.itemsPerPage")}
    </p>
  </span>
  <span>
    {(() => {
      const totalPages = table.getPageCount();
      const currentPage = table.getState().pagination.pageIndex;
      const maxButtons = isMobile ? 2 : 5;
      const halfMaxButtons = Math.floor(maxButtons / 2);

      let startPage = Math.max(0, currentPage - halfMaxButtons);
      let endPage = Math.min(totalPages - 1, currentPage + halfMaxButtons);

      if (endPage - startPage + 1 < maxButtons) {
        if (startPage === 0) {
          endPage = Math.min(maxButtons - 1, totalPages - 1);
        } else if (endPage >= totalPages - 2) {
          startPage = Math.max(0, totalPages - maxButtons);
        }
      }

      return (
        <>
          {startPage > 0 && (
            <button
              className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
              onClick={() => table.setPageIndex(0)}
            >
              1
            </button>
          )}
          {startPage > 1 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
            <button
              key={startPage + i}
              className={`px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1 ${currentPage === startPage + i ? 'border border-custom-bgPurple text-custom-bgPurple rounded-lg' : ''}`}
              onClick={() => table.setPageIndex(startPage + i)}
            >
              {startPage + i + 1}
            </button>
          ))}
          {endPage < totalPages - 3 && <span className="px-2 py-1 md:px-3 md:py-1.5">...</span>}
          {endPage < totalPages - 2 && (
            <>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 2)}
              >
                {totalPages - 1}
              </button>
              <button
                className="px-2 py-1 md:px-3 md:py-1.5 border rounded-lg mx-1"
                onClick={() => table.setPageIndex(totalPages - 1)}
              >
                {totalPages}
              </button>
            </>
          )}
        </>
      );
    })()}
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.nextPage()}
      disabled={!table.getCanNextPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.next")}
    </button>
    <button
      className="p-2 hidden md:inline"
      onClick={() => table.lastPage()}
      disabled={!table.getCanPreviousPage()}
      style={{ color: "#5A5A5A" }}
    >
      {t("periodReport.end")}
    </button>
  </span>
</div>
}
{detailsOn ? 
<div className="mt-4 flex flex-wrap w-full md:w-1/2">{statusLegend.map((status: any, index: any) => {
  return (
  <div className="m-1" key={`legend-${index}`}>
    <span className="bg-custom-statusBg p-0.5 rounded-[2px] mr-1">{status.short}:</span>
    <span>{status.long}</span>
  </div>
  )
  })}</div> :
<div></div>
}
{selectedRows && <ExportReceipt selectedIds={numbersString} fromDate={fromDate}/>}
    </Wrapper>
  );
};

export default Table;