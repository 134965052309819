import React from "react";
import { ButtonProps } from "./Button.type";
import classNames from "classnames";

const Button: React.FC<ButtonProps> = ({
  classes,
  type = "primary",
  disabled = false,
  onClick,
  label,
}) => {
  const ButtonTypeClasses = classNames(
    {
      " hover:bg-custom-bgPurple font-semibold hover:text-white py-2 px-4 border":
        type === "primary",
      "bg-custom-bgBlue hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border border-gray-300":
        type === "secondary",
      "bg-blue hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4":
        type === "custom",
    },
    {
      "bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed":
        disabled,
    },
  );

  const BtnClasses = classNames(ButtonTypeClasses, classes);

  return (
    <button disabled={disabled} className={BtnClasses} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
