import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface ApiResponse<T> {
  data: T;
  error: any;
  loading: boolean;
}

const useAxios = () => {
  const [response, setResponse] = useState<AxiosResponse<any> | undefined>(
    undefined,
  );
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (
    path: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<any>> => {
    setLoading(true);
    try {
      const result = await axios(path, config);
      setResponse(result);
      setError(result.data.error);
      setLoading(false);
      return { data: result.data, error: result.data.error, loading: false };
    } catch (error) {
      setError(error);
      setLoading(false);
      return { data: null, error, loading: false };
    }
  };

  return { response, error, loading, fetchData };
};

export default useAxios;
