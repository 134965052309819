import React from "react";
import { TextProps } from "./Text.type";
import classNames from "classnames";

const Text: React.FC<TextProps> = ({
  children,
  classes = "",
  size = "",
  color = "black",
  align = "text-left",
  limit
}) => {
  const TextClasses = classNames(classes, size, color, align);
  
  const isTruncated = limit && typeof children === "string" && children.length > limit;
  const truncatedText = isTruncated
    ? `${children.slice(0, limit)}...`
    : children;

  return (
    <p className={`${TextClasses}`} title={isTruncated ? children : undefined}>
      {truncatedText}
    </p>
  );
};

export default Text;
