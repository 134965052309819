import React, { createContext, useState, useEffect, ReactNode } from "react";
import { useCookies } from "react-cookie";

interface ThemeContextType {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  toggleTheme: () => void;
  isLoggedIn: boolean;
}

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: false,
  setIsDarkMode: () => {},
  toggleTheme: () => {},
  isLoggedIn: false,
});

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cookies] = useCookies(["isLogIn"]); // Access the cookie
  const isLoggedIn = cookies.isLogIn === "true"; // Convert to boolean

  // State to manage dark mode
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  // Toggle function for dark mode
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      document.documentElement.classList.toggle("dark", newMode);
      localStorage.setItem("isDarkMode", newMode.toString());
      return newMode;
    });
  };

  // Initialize the theme based on localStorage and system preference
  useEffect(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (savedTheme !== null) {
      setIsDarkMode(savedTheme === "true");
    } else {
      setIsDarkMode(prefersDarkMode);
    }

    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
        toggleTheme,
        isLoggedIn,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
