import React from "react";
import classNames from "classnames";
import { HeadingProps } from "./Heading.type";

const Heading: React.FC<HeadingProps> = ({
  children,
  classes = "",
  size = "h1",
  color = "blue",
  align = "text-left",
}) => {
  const HeadingTag = size as keyof JSX.IntrinsicElements;

  const textSizeMap = {
    h1: "text-2xl",
    h2: "text-xl",
    h3: "text-large",
    h4: "text-medium",
    h5: "text-small",
    h6: "text-xs",
  };

  const HeadingClasses = classNames(
    "font-bold",
    classes,
    color,
    align,
    textSizeMap[size],
  );

  return <HeadingTag className={HeadingClasses}>{children}</HeadingTag>;
};

export default Heading;
