import { getCoreRowModel, getPaginationRowModel } from "@tanstack/react-table";
import { SoldProduct } from "./SoldProductsReports.type";  // Adjust path if needed

// Function to generate table options dynamically
export const getTableOptions = (data: SoldProduct[], columns: any) => ({
  data: data,
  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  initialState: {
    pagination: {
      pageIndex: 0,  // Initial page index
      pageSize: 10,  // Default page size
    },
  },
});

