import { useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import { useTranslation } from "react-i18next";
import { Location, PeriodReportFilterProps } from "./PeriodReportFilters.type";
import useAxios from "../../hooks/useAxios";
import { API_ROUTES } from "../../config";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import useDateFormatter from "../../hooks/useDateFormatter";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import Dropdown from "../common/dropdown/Dropdown";
import dayjs from "dayjs";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";

const PeriodReportFilter: React.FC<PeriodReportFilterProps> = ({
  sendFromDateToParent,
  sendToDateToParent,
  exportType,
  fileUrl, 
  getDataBe,
  handleExportChange
}) => {
  const { t } = useTranslation();
  const menuId = localStorage.getItem("MI")

  const UserMenus = localStorage.getItem("UserMenus");
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: Location
  ) => {
    return { value: location.IdMenu, label: location.MenuName };
  });
  const { formatDateSP } = useDateFormatter();
  const currentDate = new Date();
  const [fromDate, setFromDate] = useState<string>(formatDateSP(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)));
  const [toDate, setToDate] = useState<string>(formatDateSP(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)));  
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const { fetchData } = useAxios();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<Date[]>();
  const [allowedDatesToPicker, setAllowedDatesToPicker] = useState<Date[]>();
  const dateRangeOptions = [
    {value: "date", label: t("dateRange.custom")},
    {value: "week", label:  t("dateRange.weekly")},
    {value: "month", label: t("dateRange.monthly")},
    {value: "quarter", label:  t("dateRange.quarterly")},
    {value: "year", label:  t("dateRange.yearly")}
  ]
  const [pickerMode, setPickerMode] = useState(dateRangeOptions[0].value);

  const handleSelectChange = (selectedOption: any) => {

    const selectedLocationData = UserMenusParsed.find(
      (location: Location) => location.IdMenu === selectedOption
    );
    const selectedMenuArray = { IdMenu: selectedLocationData.IdMenu, MenuName: selectedLocationData.MenuName }
    localStorage.setItem("menuId", JSON.stringify(selectedMenuArray))
    localStorage.setItem("MI", JSON.stringify(selectedMenuArray.IdMenu))

    if(selectedLocationData) {
      localStorage.setItem("longitude", selectedLocationData.Longitude);
      localStorage.setItem("latitude", selectedLocationData.Latitude);
      localStorage.setItem("fullAddress", selectedLocationData.FullAddress)
    }
    window.location.reload();

  };
  const handleModeChange = (selectedMode: any) => {
    const newMode = selectedMode[0]; 
    setPickerMode(newMode);
  };

  const getNewFromDate = (mode: string): Date => {
    const now = new Date();
    switch (mode) {
      case "month":
        return new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
      case "week":
        const firstDayOfWeek = new Date(now);
        const dayOfWeek = firstDayOfWeek.getDay() || 7; // Ensure 0 (Sunday) is treated as 7
        firstDayOfWeek.setDate(now.getDate() - dayOfWeek + 1); // Set to the first day of the week (Monday)
        return firstDayOfWeek;
      case "quarter":
        const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3; // Start of the quarter
        return new Date(now.getFullYear(), quarterStartMonth, 1);
      case "year":
        return new Date(now.getFullYear(), 0, 1); // First day of the year
      default:
        return (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
    }
  };
  
  const getNewToDate = (mode: string): Date => {
    const now = new Date();
    switch (mode) {
      case "month":
        return new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
      case "week":
        const lastDayOfWeek = new Date(now);
        const dayOfWeek = lastDayOfWeek.getDay() || 7; // Ensure 0 (Sunday) is treated as 7
        lastDayOfWeek.setDate(now.getDate() + (7 - dayOfWeek)); // Set to the last day of the week (Sunday)
        return lastDayOfWeek;
      case "quarter":
        const quarterEndMonth = Math.floor(now.getMonth() / 3) * 3 + 2; // End of the quarter
        return new Date(now.getFullYear(), quarterEndMonth + 1, 0); // Last day of the quarter
      case "year":
        return new Date(now.getFullYear(), 11, 31); // Last day of the year
      default:
        return (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
    }
  };

  useEffect(() => {
    // Update fromDate and toDate when pickerMode changes
    const newFromDate = formatDateSP(getNewFromDate(pickerMode));
    const newToDate = formatDateSP(getNewToDate(pickerMode));

    setFromDate(newFromDate);
    setToDate(newToDate);
  }, [pickerMode]);

  useEffect(() => {
    if (
       location.pathname === "/reports/periodReport"
    ) {
      if (menuId) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: menuId,
            "TargetDate": formatDate(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDate(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
            setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
            setAllowedDatesToPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [menuId, location.pathname]);

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    menuId, 
    token, 
    locationPathname: location.pathname 
  });

  useEffect(() => {
    const parsedFromDate = dayjs(fromDate, 'DD/MM/YYYY');
    if(pickerMode === "week") {
      const toDate = parsedFromDate.endOf('week');
      const formattedToDate = toDate.format('DD/MM/YYYY');
      setToDate(formattedToDate)
    } else if (pickerMode === "month") {
      const toDate = parsedFromDate.endOf("month");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    } else if (pickerMode === "year") {
      const toDate = parsedFromDate.endOf("year");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    } else if (pickerMode === "quarter") {
      const toDate = parsedFromDate.endOf("quarter");
      const formattedToDate = toDate.format("DD/MM/YYYY");
      setToDate(formattedToDate)
    }
  }, [fromDate, pickerMode])


  useEffect(() => {
    sendFromDateToParent(fromDate);
    sendToDateToParent(toDate);
  }, [fromDate, toDate]);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const { disabledDate, disabledToDate } = useDateDisabling({ allowedDatesFromPicker, allowedDatesToPicker });

  const lsMenuId = localStorage.getItem("menuId");
  const parsedLsMenuId = lsMenuId && JSON.parse(lsMenuId);
  const option = parsedLsMenuId ? { value: parsedLsMenuId.IdMenu, label: parsedLsMenuId.MenuName } : null;

  return (
    <Wrapper onClick={() => {}} classes="pt-10 md:pt-20 flex flex-col items-center justify-center md:pb-4 fixed left-0 md:left-[20%] right-0 bg-[#FFFFFF40] backdrop-blur-[35px] z-10">
      <div className="w-[95%] flex flex-col">

        <div className="w-full">
            <Heading
              align="left"
              children={t("periodReport.title")}
              classes="pt-4 md:pt-2 min-w-full text-[22px] md:text-4xl"
              color=""
              size="h1"
            />
            <Text
              align=""
              children={t("periodReport.subTitle")}
              classes="text-[15px] md:text-base font-thin"
              color="text-custom-textGraySec"
              size=""
            />
          </div>

        <Select
          classes="md:hidden flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-3 py-[7px]"
          options={options}
          onChange={handleSelectChange}
          defaultValue={option}
          />
          </div>

        <div className="w-[95%] flex flex-col md:flex-row justify-between items-end mb-4 mt-2 md:mt-4">
          <div className="w-full md:w-1/6">
          <p className="text-sm md:text-base mb-2">{t("dateRange.title")}</p>

          <Dropdown isMultiSelect={false} onChange={handleModeChange} options={dateRangeOptions} title={dateRangeOptions.length > 0 && dateRangeOptions[0].label} defaultValue={dateRangeOptions}/>
          </div>
        <div className="w-full md:w-2/5 mt-2 flex gap-2 ">
        
<div className="text-left w-1/2">
  <p className="text-sm md:text-base mb-2">{t("periodReport.from")}</p>
  <span className="grid grid-cols-1 gap-2">
    {fromDate && 
        <CustomDatePicker
        dateValue={fromDate}
        onDateChange={setFromDate}
        disabledDate={disabledDate}
        handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
        placeholder={t("datePicker.placeholder")}
        allowedDates={allowedDatesFromPicker}
        mode={pickerMode}
        />
      }
      </span>
</div>
<div className="text-sm md:text-base text-left w-1/2">
  <p className="mb-2">{t("periodReport.to")}</p>
  <span className="grid grid-cols-1 gap-2">

    {toDate && 
      <CustomDatePicker
      dateValue={toDate}
      onDateChange={setToDate}
      disabledDate={disabledToDate}
      handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesToPicker)}}
      placeholder={t("datePicker.placeholder")}
      isToDatePicker={true}
      allowedDates={allowedDatesToPicker}
      isPeriodReport={true}
      mode={pickerMode}
      />
} 
  </span>
</div>
</div>

        </div>
        <div className="w-[95%]">

        <ExportFilterActions
          fileUrl={fileUrl}
          exportType={exportType}
          handleExportChange={handleExportChange}
          fetchReportData={getDataBe}
        />
        </div>
    </Wrapper>
  );
};

export default PeriodReportFilter;
