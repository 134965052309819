import React from "react";
import classNames from "classnames";

interface LabelProps {
  text: string;
  htmlFor: string;
  classes?: string;
}

const Label: React.FC<LabelProps> = ({ text, htmlFor, classes = "" }) => {
  const labelClasses = classNames(classes);

  return (
    <label htmlFor={htmlFor} className={labelClasses}>
      {text}
    </label>
  );
};

export default Label;
