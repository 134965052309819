import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import ZReportRow from "../zReportRow/ZReportRow";
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from "react";

interface Props {
    ownUse: any;
    isByStatus?: boolean;
    corrections: any;
    discount: any;
    outOfTurnover: any;
}


const OtherComponent: React.FC<Props> = ({ownUse, isByStatus, corrections, discount, outOfTurnover}) => {
    const { t } = useTranslation();
    const [sumsByStatus, setSumsByStatus] = useState<any>([]);

useEffect(() => {
  const sums: { [key: number]: { status: string; quantity: number } } = {};

  ownUse.forEach((order: any) => {
    const { IdStatus, Status, Quantity } = order;
    if (!sums[IdStatus]) {
      sums[IdStatus] = { status: Status, quantity: 0 };
    }
    sums[IdStatus].quantity += Quantity;
  });

  const mappedSums = Object.keys(sums).map((idStatus) => ({
    idStatus: parseInt(idStatus, 10), // Explicitly parse to number
    status: sums[parseInt(idStatus, 10)].status, // Explicitly parse to number
    quantity: sums[parseInt(idStatus, 10)].quantity, // Explicitly parse to number
  }));

  setSumsByStatus(mappedSums);
}, [ownUse]);

let totalDiscountAmount;
let totalCorrcetionsAmount;
// let totalPaymentsAmount;
{discount.length > 0 ? (totalDiscountAmount = discount.reduce((sum: any, item: any) => sum + item.Amount, 0)) : (totalDiscountAmount = 0)}
{corrections.length > 0 ? (totalCorrcetionsAmount = corrections.reduce((sum: any, item: any) => sum + item.Quantity, 0)) : (totalCorrcetionsAmount = 0)}
// {paymentData.length > 0 ? (totalPaymentsAmount = paymentData.reduce((sum: any, item: any) => sum + item.Ammount, 0)) : (totalPaymentsAmount = 0)}

  return (
    <>
    {isByStatus ? (    <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
    {sumsByStatus.map((item: any) => {
        return(
          <ZReportRow key={uuidv4()} title={item.status} value={item.quantity.toFixed(2)}/>
        )
      })}
    </Wrapper>
    ) : ( 
    <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg">
          <ZReportRow title={`${t("zreport.corrections")} (${corrections.length}x)`} value={totalCorrcetionsAmount.toFixed(2)}/>
          <ZReportRow title={`${t("zreport.discount")} (${discount.length}x)`} value={totalDiscountAmount.toFixed(2)} />
          {outOfTurnover.Orders.length > 0 ? ( <ZReportRow title={`${t("zreport.outOfTurnover")} (${outOfTurnover.Orders.length}x)`} value={outOfTurnover && outOfTurnover.DailyTotal[0].Total} color="bg-custom-bgOrange"/>) : (<></>)}
        </Wrapper>
    )}
    </>

  );
};

export default OtherComponent;
