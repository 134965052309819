const SupportIcon = () => {
    const svgIcon = `
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.72266 11.5008V7.37372C4.73491 6.56247 4.90712 5.76163 5.22942 5.01705C5.55171 4.27248 6.01777 3.59882 6.60087 3.03467C7.18396 2.47053 7.87265 2.02698 8.62747 1.72945C9.38227 1.43192 10.1884 1.28625 10.9996 1.3008C11.8108 1.28625 12.6169 1.43192 13.3717 1.72945C14.1265 2.02698 14.8152 2.47053 15.3983 3.03467C15.9814 3.59882 16.4475 4.27248 16.7698 5.01705C17.092 5.76163 17.2643 6.56247 17.2765 7.37372V11.5008" stroke="#898989" stroke-width="1.47333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.36904 9.146H3.93827C4.14636 9.146 4.34594 9.22866 4.49307 9.37581C4.64021 9.52294 4.72288 9.72252 4.72288 9.93061V14.6383C4.72288 14.8464 4.64021 15.046 4.49307 15.1931C4.34594 15.3403 4.14636 15.4229 3.93827 15.4229H2.36904C1.95284 15.4229 1.55371 15.2576 1.25942 14.9633C0.965134 14.669 0.799805 14.2699 0.799805 13.8537V10.7152C0.799805 10.299 0.965134 9.8999 1.25942 9.60561C1.55371 9.31133 1.95284 9.146 2.36904 9.146Z" stroke="#898989" stroke-width="1.47333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.6302 15.4229H18.061C17.8529 15.4229 17.6533 15.3403 17.5061 15.1931C17.3591 15.046 17.2764 14.8464 17.2764 14.6383V9.93061C17.2764 9.72252 17.3591 9.52294 17.5061 9.37581C17.6533 9.22866 17.8529 9.146 18.061 9.146H19.6302C20.0464 9.146 20.4456 9.31133 20.7398 9.60561C21.034 9.8999 21.1994 10.299 21.1994 10.7152V13.8537C21.1994 14.2699 21.034 14.669 20.7398 14.9633C20.4456 15.2576 20.0464 15.4229 19.6302 15.4229Z" stroke="#898989" stroke-width="1.47333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1377 19.7381C14.9701 19.7381 15.7683 19.4074 16.3569 18.8188C16.9455 18.2302 17.2762 17.4319 17.2762 16.5996V13.0688" stroke="#898989" stroke-width="1.47333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1383 19.7384C14.1383 20.2586 13.9316 20.7576 13.5637 21.1254C13.1959 21.4933 12.6969 21.6999 12.1767 21.6999H9.82287C9.30264 21.6999 8.80371 21.4933 8.43585 21.1254C8.068 20.7576 7.86133 20.2586 7.86133 19.7384C7.86133 19.2182 8.068 18.7192 8.43585 18.3514C8.80371 17.9835 9.30264 17.7769 9.82287 17.7769H12.1767C12.6969 17.7769 13.1959 17.9835 13.5637 18.3514C13.9316 18.7192 14.1383 19.2182 14.1383 19.7384Z" stroke="#898989" stroke-width="1.47333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
          
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default SupportIcon;
  