const BlackLogoIcon = () => {
    const svgIcon = `<svg width="154" height="32" viewBox="0 0 154 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_614_11237)">
    <path d="M17.7775 31.893C13.2302 31.8884 8.87056 30.2126 5.65497 27.2334C2.43938 24.2541 0.630441 20.2147 0.625 16.0012V0.10791H34.9364V16.0012C34.931 20.2157 33.1212 24.256 29.9042 27.2354C26.6873 30.2148 22.326 31.8899 17.7775 31.893ZM4.31545 3.52743V16.0012C4.31545 19.3094 5.73378 22.4822 8.25841 24.8215C10.783 27.1608 14.2072 28.475 17.7775 28.475C21.3479 28.475 24.7721 27.1608 27.2967 24.8215C29.8213 22.4822 31.2396 19.3094 31.2396 16.0012V3.52743H4.31545Z" fill="#101828"/>
    <path d="M17.7305 17.84C18.8264 17.84 19.7148 17.0168 19.7148 16.0013C19.7148 14.9858 18.8264 14.1626 17.7305 14.1626C16.6345 14.1626 15.7461 14.9858 15.7461 16.0013C15.7461 17.0168 16.6345 17.84 17.7305 17.84Z" fill="#101828"/>
    <path d="M17.6881 10.7792L7.87695 5.52979V16.0272L17.6881 10.7792Z" fill="#101828"/>
    <path d="M17.6875 10.7792L27.4987 5.52979V16.0272L17.6875 10.7792Z" fill="#101828"/>
    <path d="M52.0214 22.7324C50.433 22.7575 48.8629 22.415 47.4534 21.7361C46.1359 21.0967 45.0311 20.137 44.2578 18.9598C43.4746 17.775 43.083 16.3949 43.083 14.8194C43.083 13.2244 43.4746 11.8287 44.2578 10.6322C45.0237 9.44761 46.1301 8.48379 47.4534 7.84855C48.8664 7.17984 50.435 6.84277 52.0214 6.86694C53.4263 6.84679 54.8163 7.13602 56.0771 7.71083C57.2929 8.28599 58.2915 9.18981 58.9406 10.3026C59.6458 11.4688 59.9989 12.972 60 14.8121C60 16.6112 59.6468 18.0934 58.9406 19.2587C58.2894 20.374 57.2918 21.2825 56.0771 21.8665C54.8201 22.4534 53.4288 22.7504 52.0214 22.7324ZM52.5511 29.6344C50.9347 29.6393 49.3253 29.4382 47.768 29.0367C46.2332 28.6362 44.9588 28.0502 43.9447 27.2786L45.7979 24.426C46.6662 25.0664 47.6532 25.555 48.7104 25.8677C49.8817 26.2356 51.1104 26.4222 52.3471 26.42C54.2677 26.42 55.6802 26.0059 56.5846 25.1776C57.4891 24.3494 57.9418 23.0967 57.9429 21.4197V18.4997L58.2733 14.8194L58.1405 11.1069V7.08963H62.0792V20.9845C62.0792 23.9489 61.2628 26.1319 59.63 27.5335C57.9972 28.9351 55.6375 29.6354 52.5511 29.6344ZM52.6507 19.4814C53.6162 19.497 54.571 19.2917 55.4304 18.8836C56.2184 18.5076 56.8761 17.9332 57.3278 17.2266C57.7727 16.4828 58.0062 15.6464 58.0062 14.796C58.0062 13.9456 57.7727 13.1092 57.3278 12.3654C56.8735 11.6683 56.2158 11.1046 55.4304 10.7392C54.5681 10.3396 53.6146 10.1386 52.6507 10.1531C51.6813 10.1399 50.7225 10.3408 49.8536 10.7392C49.0588 11.1002 48.3922 11.6645 47.9325 12.3654C47.4875 13.1092 47.2541 13.9456 47.2541 14.796C47.2541 15.6464 47.4875 16.4828 47.9325 17.2266C48.3896 17.9371 49.0561 18.5119 49.8536 18.8836C50.7196 19.2906 51.6797 19.4958 52.6507 19.4814Z" fill="#101828"/>
    <path d="M95.3825 23.6825C93.4187 23.6825 91.7026 23.3197 90.2342 22.5939C88.8208 21.9159 87.64 20.8852 86.8252 19.6184C86.0188 18.3613 85.6161 16.9148 85.6172 15.2788C85.6182 13.6427 86.0098 12.1957 86.792 10.9377C87.5622 9.69189 88.6838 8.66378 90.0366 7.96356C91.4153 7.23688 92.9876 6.87354 94.7532 6.87354C96.4746 6.87354 98.0083 7.22662 99.3544 7.9328C100.689 8.62776 101.789 9.65227 102.533 10.8923C103.304 12.162 103.691 13.6652 103.692 15.4018C103.692 15.5483 103.692 15.7227 103.674 15.938C103.657 16.1534 103.647 16.3424 103.625 16.505H88.8934V13.9602H101.44L99.7845 14.7572C99.8221 13.8984 99.6108 13.046 99.1726 12.2885C98.7646 11.5833 98.1509 10.9985 97.4017 10.6007C96.5913 10.1883 95.6775 9.9821 94.7532 10.003C93.8246 9.98826 92.9074 10.194 92.0889 10.6007C91.3172 10.9881 90.6887 11.5812 90.2848 12.3032C89.8505 13.0906 89.6332 13.9654 89.6523 14.8495V15.4692C89.6331 16.3819 89.8789 17.2825 90.3639 18.0771C90.8429 18.8348 91.5508 19.446 92.4004 19.8352C93.3577 20.2694 94.4134 20.4845 95.479 20.4622C96.3991 20.4762 97.3129 20.3198 98.1669 20.0022C98.9668 19.6931 99.6888 19.2337 100.287 18.6529L102.537 21.0453C101.709 21.9118 100.672 22.5845 99.5094 23.0086C98.1934 23.4774 96.7921 23.7062 95.3825 23.6825Z" fill="#101828"/>
    <path d="M112.799 23.6825C111.356 23.6898 109.919 23.5093 108.53 23.1463C107.183 22.7888 106.101 22.3542 105.285 21.8424L106.866 18.9283C107.751 19.4358 108.709 19.8232 109.712 20.0784C110.799 20.3713 111.925 20.5193 113.057 20.5179C114.335 20.5179 115.268 20.3592 115.854 20.0418C116.437 19.7253 116.731 19.2902 116.731 18.7393C116.74 18.5346 116.689 18.3316 116.584 18.1513C116.479 17.971 116.324 17.8199 116.135 17.7137C115.655 17.4503 115.128 17.2687 114.579 17.1775C113.938 17.0544 113.226 16.9421 112.443 16.8405C111.66 16.739 110.877 16.6007 110.093 16.4259C109.346 16.2654 108.627 16.0069 107.959 15.6582C107.327 15.329 106.793 14.8605 106.403 14.2942C106.005 13.7209 105.806 12.9541 105.807 11.994C105.791 11.0253 106.133 10.0806 106.775 9.31584C107.416 8.54813 108.315 7.94989 109.472 7.5211C110.63 7.09232 112.004 6.87744 113.594 6.87646C114.803 6.88256 116.007 7.01277 117.185 7.26471C118.259 7.46767 119.292 7.82455 120.248 8.32251L118.625 11.2366C117.847 10.8006 116.998 10.4849 116.11 10.3018C115.273 10.1302 114.418 10.0427 113.561 10.0411C112.325 10.0411 111.397 10.2095 110.78 10.5465C110.162 10.8835 109.853 11.3181 109.853 11.8504C109.84 12.0668 109.888 12.2826 109.992 12.4764C110.097 12.6702 110.254 12.8352 110.449 12.9551C110.927 13.2298 111.454 13.4219 112.005 13.5221C112.638 13.6554 113.357 13.7785 114.14 13.8898C114.922 14.0012 115.7 14.1462 116.474 14.3191C117.215 14.4815 117.933 14.7275 118.61 15.0517C119.251 15.3606 119.793 15.8204 120.181 16.3849C120.58 16.9563 120.779 17.702 120.779 18.6221C120.804 19.5954 120.458 20.5459 119.802 21.3061C119.15 22.0523 118.235 22.635 117.055 23.054C115.876 23.473 114.457 23.6825 112.799 23.6825Z" fill="#101828"/>
    <path d="M129.573 23.6816C127.631 23.6816 126.125 23.2167 125.054 22.2869C123.983 21.357 123.448 19.9896 123.449 18.1846V3.46777H127.62V18.0689C127.62 18.8502 127.835 19.4534 128.265 19.8782C128.696 20.3031 129.297 20.518 130.07 20.5229C130.996 20.5229 131.769 20.2978 132.388 19.8475L133.578 22.5784C133.031 22.9641 132.406 23.2455 131.741 23.4062C131.035 23.5882 130.306 23.6809 129.573 23.6816ZM125.767 10.2775V7.21108H132.354V10.2775H125.767Z" fill="#101828"/>
    <path d="M138.137 4.5111C137.823 4.52027 137.51 4.46771 137.22 4.35685C136.929 4.246 136.667 4.07937 136.45 3.86792C136.234 3.66061 136.064 3.41559 135.95 3.14701C135.836 2.87844 135.781 2.59164 135.788 2.3032C135.78 2.01704 135.835 1.73238 135.949 1.46627C136.062 1.20016 136.233 0.958105 136.45 0.754605C136.78 0.445173 137.201 0.233803 137.661 0.147508C138.121 0.061213 138.598 0.103911 139.032 0.270146C139.465 0.436381 139.835 0.718602 140.095 1.08075C140.354 1.44289 140.492 1.8685 140.489 2.3032C140.495 2.59284 140.438 2.88063 140.321 3.14944C140.205 3.41825 140.031 3.6626 139.81 3.86792C139.595 4.07675 139.335 4.24176 139.047 4.35249C138.759 4.46321 138.449 4.51723 138.137 4.5111ZM136.082 23.4679V7.08818H140.193V23.4679H136.082Z" fill="#101828"/>
    <path d="M149.369 23.6816C147.427 23.6816 145.921 23.2167 144.85 22.2869C143.779 21.357 143.244 19.9896 143.245 18.1846V3.46777H147.416V18.0689C147.416 18.8502 147.631 19.4534 148.061 19.8782C148.491 20.3031 149.093 20.518 149.865 20.5229C150.792 20.5229 151.565 20.2978 152.183 19.8475L153.374 22.5784C152.827 22.9641 152.202 23.2455 151.537 23.4062C150.831 23.5882 150.102 23.6809 149.369 23.6816ZM145.562 10.2775V7.21108H152.15V10.2775H145.562Z" fill="#101828"/>
    <path d="M78.7286 7.12988V16.0743C78.7286 17.4651 78.298 18.5488 77.4368 19.3253C76.5756 20.1018 75.4282 20.4925 73.9946 20.4974C72.6696 20.4974 71.6376 20.1291 70.8987 19.3927C70.1597 18.6562 69.7908 17.5931 69.7918 16.2032V7.12988H65.6191V16.3805C65.6191 18.6924 66.2758 20.5022 67.5893 21.8101C68.9027 23.1179 70.7179 23.7723 73.0348 23.7733C74.7109 23.7733 76.1397 23.4612 77.3214 22.8371C77.9109 22.5286 78.4418 22.1327 78.8931 21.665V23.4671H82.8998V7.12988H78.7286Z" fill="#101828"/>
    </g>
    <defs>
    <clipPath id="clip0_614_11237">
    <rect width="154" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
 
      `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default BlackLogoIcon;
  