const Weather2Icon = () => {
    const svgIcon = `
    <svg width="119" height="129" viewBox="0 0 326 359" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_f_958_2151)">
    <path d="M172.855 88.1342C147.788 122.168 143.442 168.614 165.554 206.914C187.666 245.213 230.063 264.672 272.07 259.981C263.138 272.108 251.576 282.659 237.642 290.704C184.604 321.325 117.296 304.038 87.3042 252.091C57.3128 200.145 75.9957 133.21 129.034 102.589C142.967 94.544 157.886 89.8059 172.855 88.1342Z" fill="#FFEE94"/>
    </g>
    <g filter="url(#filter1_i_958_2151)">
    <path d="M170.517 102.476C147.636 133.543 143.668 175.941 163.853 210.902C184.038 245.863 222.739 263.625 261.085 259.343C252.931 270.413 242.377 280.044 229.657 287.388C181.243 315.34 119.801 299.56 92.4241 252.141C65.047 204.723 82.1014 143.622 130.516 115.67C143.235 108.327 156.854 104.002 170.517 102.476Z" fill="url(#paint0_linear_958_2151)"/>
    </g>
    <g filter="url(#filter2_b_958_2151)">
    <g filter="url(#filter3_i_958_2151)">
    <path d="M155.68 270.253C156.129 267.701 156.364 265.075 156.364 262.395C156.364 237.324 135.85 217 110.545 217C91.7336 217 75.5696 228.232 68.5132 244.297C62.7968 239.407 55.3789 236.455 47.2727 236.455C29.198 236.455 14.5455 251.133 14.5455 269.24C14.5455 270.249 14.5909 271.246 14.6798 272.231C5.98618 276.45 0 285.303 0 295.541C0 309.867 11.722 321.481 26.1818 321.481H149.818C164.278 321.481 176 309.867 176 295.541C176 283.211 167.317 272.89 155.68 270.253Z" fill="url(#paint1_linear_958_2151)"/>
    </g>
    </g>
    <g filter="url(#filter4_b_958_2151)">
    <g filter="url(#filter5_i_958_2151)">
    <path d="M275.68 112.253C276.129 109.701 276.364 107.075 276.364 104.395C276.364 79.324 255.85 59 230.545 59C211.734 59 195.57 70.2324 188.513 86.2972C182.797 81.4074 175.379 78.455 167.273 78.455C149.198 78.455 134.545 93.1335 134.545 111.24C134.545 112.249 134.591 113.246 134.68 114.231C125.986 118.45 120 127.303 120 137.541C120 151.867 131.722 163.481 146.182 163.481H269.818C284.278 163.481 296 151.867 296 137.541C296 125.211 287.317 114.89 275.68 112.253Z" fill="url(#paint2_linear_958_2151)"/>
    </g>
    </g>
    <g filter="url(#filter6_di_958_2151)">
    <path d="M194.765 18.3823C184.249 23.0521 180.144 26.9985 176.382 36.7646C173.386 27.3001 169.702 23.0785 158 18.3823C170.038 14.4602 173.305 9.9199 176.382 1.2095e-05C180.164 9.14263 182.499 14.1323 194.765 18.3823Z" fill="url(#paint3_linear_958_2151)"/>
    </g>
    <g filter="url(#filter7_di_958_2151)">
    <path d="M87.7646 105.382C77.2487 110.052 73.1443 113.998 69.3823 123.765C66.3863 114.3 62.7023 110.079 51 105.382C63.0381 101.46 66.3052 96.9199 69.3823 87C73.1636 96.1426 75.499 101.132 87.7646 105.382Z" fill="url(#paint4_linear_958_2151)"/>
    </g>
    <g filter="url(#filter8_di_958_2151)">
    <path d="M256.765 206.382C246.249 211.052 242.144 214.998 238.382 224.765C235.386 215.3 231.702 211.079 220 206.382C232.038 202.46 235.305 197.92 238.382 188C242.164 197.143 244.499 202.132 256.765 206.382Z" fill="url(#paint5_linear_958_2151)"/>
    </g>
    <defs>
    <filter id="filter0_f_958_2151" x="19.9941" y="35.1343" width="305.076" height="323.712" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feGaussianBlur stdDeviation="26.5" result="effect1_foregroundBlur_958_2151"/>
    </filter>
    <filter id="filter1_i_958_2151" x="79.3618" y="102.476" width="181.723" height="202.735" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="4"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.55 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_958_2151"/>
    </filter>
    <filter id="filter2_b_958_2151" x="-27" y="190" width="230" height="158.48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="13.5"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_958_2151"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_958_2151" result="shape"/>
    </filter>
    <filter id="filter3_i_958_2151" x="0" y="217" width="176" height="114.48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="11"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_958_2151"/>
    </filter>
    <filter id="filter4_b_958_2151" x="93" y="32" width="230" height="158.48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="13.5"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_958_2151"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_958_2151" result="shape"/>
    </filter>
    <filter id="filter5_i_958_2151" x="120" y="59" width="176" height="114.48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="11"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_958_2151"/>
    </filter>
    <filter id="filter6_di_958_2151" x="149.233" y="0" width="54.2995" height="56.5321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="11"/>
    <feGaussianBlur stdDeviation="4.38372"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.285052 0 0 0 0 0.179688 0 0 0 0 0.9375 0 0 0 1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_958_2151"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_958_2151" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0"/>
    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_958_2151"/>
    </filter>
    <filter id="filter7_di_958_2151" x="42.2326" y="87" width="54.2995" height="56.5321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="11"/>
    <feGaussianBlur stdDeviation="4.38372"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.285052 0 0 0 0 0.179688 0 0 0 0 0.9375 0 0 0 0.28 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_958_2151"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_958_2151" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0"/>
    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_958_2151"/>
    </filter>
    <filter id="filter8_di_958_2151" x="211.233" y="188" width="54.2995" height="56.5321" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="11"/>
    <feGaussianBlur stdDeviation="4.38372"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.285052 0 0 0 0 0.179688 0 0 0 0 0.9375 0 0 0 0.8 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_958_2151"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_958_2151" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0"/>
    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_958_2151"/>
    </filter>
    <linearGradient id="paint0_linear_958_2151" x1="175.5" y1="85.9999" x2="139.175" y2="305.951" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFEE94"/>
    <stop offset="1" stop-color="#FF9900"/>
    </linearGradient>
    <linearGradient id="paint1_linear_958_2151" x1="11.1943" y1="312.152" x2="188.749" y2="179.375" gradientUnits="userSpaceOnUse">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0.58"/>
    </linearGradient>
    <linearGradient id="paint2_linear_958_2151" x1="131.194" y1="154.152" x2="308.749" y2="21.3745" gradientUnits="userSpaceOnUse">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0.58"/>
    </linearGradient>
    <linearGradient id="paint3_linear_958_2151" x1="171.064" y1="38.4872" x2="188.366" y2="6.0026" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF9900"/>
    <stop offset="1" stop-color="#FFEE94"/>
    </linearGradient>
    <linearGradient id="paint4_linear_958_2151" x1="64.0645" y1="125.487" x2="81.366" y2="93.0026" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF9900"/>
    <stop offset="1" stop-color="#FFEE94"/>
    </linearGradient>
    <linearGradient id="paint5_linear_958_2151" x1="233.064" y1="226.487" x2="250.366" y2="194.003" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF9900"/>
    <stop offset="1" stop-color="#FFEE94"/>
    </linearGradient>
    </defs>
    </svg>    
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default Weather2Icon;
  

