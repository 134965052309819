import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../dropdown/Dropdown";
import { LoadingLiveDataContext } from "../../../context/isLoadingLiveDataContext";

interface ExportFilterActionsProps {
  fileUrl: string | null;
  exportType: string;
  handleExportChange: (exportType: any) => void;
  fetchReportData: () => void;
  hideCSV?: boolean;
  isReceiptsExport?: boolean;
  receiptsPdf?: string;
}

const ExportFilterActions: React.FC<ExportFilterActionsProps> = ({
  fileUrl,
  exportType,
  handleExportChange,
  fetchReportData,
  hideCSV = false,
  isReceiptsExport = false,
  receiptsPdf, 
}) => {
  const { t } = useTranslation();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isExportPdf, setIsExportPdf] = useState<boolean>(false)

  const exportTypeOptions = [
    { value: '0', label: 'NO EXPORT' },
    { value: '1', label: 'PDF' },
    { value: '2', label: 'CSV' }
  ].filter(option => !(hideCSV && option.value === '2'));
  
  const { isLoadingLiveData } = useContext(LoadingLiveDataContext);
  useEffect(() => {
    if (isReceiptsExport && receiptsPdf) {
      setPdfUrl(receiptsPdf);
    }
  }, [isReceiptsExport, receiptsPdf]);

  useEffect(() => {
    if (pdfUrl && !isLoadingLiveData && isExportPdf) {
      window.open(pdfUrl, "_blank");
      setIsExportPdf(false);

    }
  }, [pdfUrl, isLoadingLiveData]);

  const handleGenerateReport = async () => {
    fetchReportData();
    setIsExportPdf(true);
    setPdfUrl(null)
  };


  return (
    <div className="w-full text-xs md:text-base" >
     <div className={`md:hidden mb-2 ${isReceiptsExport ? "grid grid-cols-2 gap-2" : ""}`}>
        <div className="w-full flex  gap-2 mb-4">
            <span className={`${!fileUrl ? "w-full " : "w-1/2"}`}>
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>
            {fileUrl && !isReceiptsExport &&  <span className="w-1/2 md:w-1/5">
              <button className="w-full border rounded-lg px-4 py-1.5 md:py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("periodReport.download")}</button>
            </span>}
           
        </div>
            <span className="md:w-1/5">
              <button className={`w-full rounded-lg px-4 py-2 bg-custom-bgBlue ${isReceiptsExport && exportType == "0" ? "border border-[#BDBDBD] bg-transparent text-[#BDBDBD] pointer-events-none" : isReceiptsExport ? "bg-transparent text-custom-bgBlue border border-custom-bgBlue" : "bg-custom-bgBlue text-white"  }`} onClick={isReceiptsExport ? handleGenerateReport : fetchReportData}>{isReceiptsExport ? t("receiptReport.exportReceipts") : t("periodReport.generate")}</button>
            </span>
      </div>
      <div className="hidden md:flex justify-between pb-4">
            <span className="w-1/7">
              <Dropdown isMultiSelect={false} onChange={handleExportChange} options={exportTypeOptions} title={exportTypeOptions.length > 0 && exportTypeOptions[0].label} classes="border-custom-bgBlue text-custom-bgBlue" iconColor="#0DB5D6"/>
            </span>

            <div className="grid grid-cols-2 grid-flow-row-dense w-2/5 gap-2">
              { <span className={`w-full ${fileUrl ? "" : "invisible"}`}>
                {fileUrl &&  <button className="w-full border rounded-lg px-4 py-1.5 md:py-2 border-custom-bgBlue text-custom-bgBlue" onClick={() => window.open(fileUrl, "_blank")}>{t("periodReport.download")}</button>}
             
            </span>}
            <span className={`w-full ${isReceiptsExport && exportType == "0" ? "cursor-not-allowed" : ""}`}>
              <button className={`w-full rounded-lg px-4 py-2 bg-custom-bgBlue  ${isReceiptsExport && exportType == "0" ? "border border-[#BDBDBD] bg-transparent text-[#BDBDBD] pointer-events-none" : isReceiptsExport ? "bg-transparent text-custom-bgBlue border border-custom-bgBlue" : "bg-custom-bgBlue text-white"  }`} onClick={isReceiptsExport ? handleGenerateReport : fetchReportData}>{isReceiptsExport ? t("receiptReport.exportReceipts") : t("periodReport.generate")}</button>
            </span>
  
            </div>
      </div> 
    </div>
  );
};

export default ExportFilterActions;
