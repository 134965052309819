import { useTranslation } from "react-i18next";

export const useFilterOptions = () => {
  const { t } = useTranslation();

  return [
    { value: "sales", label: t("reportsLinks.sales"), path: "/reports/sales" },
    {
      value: "z-report",
      label: t("reportsLinks.zreport"),
      path: "/reports/zreport",
    },
    {
      value: "period-report",
      label: t("reportsLinks.periodReport"),
      path: "/reports/periodReport"
    },
    {
      value: "sold-products",
      label: t("reportsLinks.soldProducts"),
      path: "/reports/soldProducts"
    },
    {
      value: "service-report",
      label: t("reportsLinks.percentageReport"),
      path: "/reports/percentageReport"
    },
    {
      value: "main-category",
      label: t("reportsLinks.mainCategory"),
      path: "/reports/mainCategory"
    }
  ];
};
