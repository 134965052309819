import { useCallback } from 'react';
import useAxios from './useAxios';
import { API_ROUTES } from '../config';
import useDateFormatter from './useDateFormatter';

interface UseUpdateAvailableDatesParams {
  menuId: any;
  token: any;
  locationPathname: string;
}

const useUpdateAvailableDates = ({ menuId, token, locationPathname }: UseUpdateAvailableDatesParams) => {
  const { fetchData } = useAxios();
  const { formatDateSP } = useDateFormatter();

  const updateAvailableDates = useCallback(async (month: Date, state: React.Dispatch<React.SetStateAction<any>>) => {
    if (menuId) {
      const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
      const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
      const requestBody = {
        IdMenu: menuId,
        "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
        "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
      };


      try {
        const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        });
        if(apiResponse.data){
          const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
              state(targetDates.sort((a:any, b:any) => b.getTime() - a.getTime()))
        }
        // state(apiResponse.data);
      } catch (error) {
        // Handle error appropriately
      }
    }
  }, [menuId, fetchData, formatDateSP, token]);

  return { updateAvailableDates };
};

export default useUpdateAvailableDates;
