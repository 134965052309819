import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>()

// export const getProductsTableHeaders = (t: any) => [
//     columnHelper.accessor('LastModified', {
//       header: t("periodReport.date")
//     }),
//     columnHelper.accessor('AvgPB', {
//       header: t("periodReport.avgPB")
//     }),
//     columnHelper.accessor('AvgPP', {
//       header: t("periodReport.avgPP")
//     }),
//     columnHelper.accessor('TotalOrders', {
//       header: t("periodReport.total")
//     }),
//   ]



  export const getVatTotalsTH = (t: any) => [
    columnHelper.accessor('VatName', {
      header: t("percentageReport.taxName")
    }),
    columnHelper.accessor('Turnover', {
      header: t("percentageReport.turnover")
    }),
    columnHelper.accessor('VAT', {
      header: t("percentageReport.vat")
    }),
  ]
  
    export const getDiscountsTH = (t: any) => [
      columnHelper.accessor('Datum', {
        header: t("percentageReport.date")
      }),
      columnHelper.accessor('TableShortDescription', {
        header: t("percentageReport.table")
      }),
      columnHelper.accessor('DateTimeDiscount', {
        header: t("percentageReport.time")
      }),
      columnHelper.accessor('DiscountText', {
        header: t("percentageReport.disc")
      }),
      columnHelper.accessor('DiscountAmount', {
        header: t("percentageReport.amt")
      }),
    ]
  
    export const getProductsTH = (t: any) => [
      columnHelper.accessor('SubCategory', {
        header: t("percentageReport.saleTotals")
      }),
      columnHelper.accessor('Quantity', {
        header: t("percentageReport.qty"),
        cell: (info) => {
          const value = info.getValue();
          return value === 0 ? value : value;
        }
      }),
      columnHelper.accessor('SubTotal', {
        header: t("percentageReport.amount"),
        cell: (info) => {
          const value = info.getValue();
          return value === 0 ? value : typeof value === 'number' ? value.toFixed(2) : value;
        }
      }),
      columnHelper.accessor('Percents', {
        header: t("percentageReport.perc"),
        cell: (info) => {
          const value = info.getValue();
          return value === 0 ? `${value}%` : typeof value === 'number' ? `${value.toFixed(2)}%` : `${value}%`;
        }
        
      })
    ]