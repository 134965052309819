import { createColumnHelper } from "@tanstack/react-table";
import { SoldProduct } from "./SoldProductsReports.type";  // Adjust path if needed

const columnHelper = createColumnHelper<SoldProduct>()

export const getProductsTableHeaders = (t: any) => [
  columnHelper.accessor('ProductName', {
    header: t("soldProducts.product"),
  }),
  columnHelper.accessor('Quantity', {
    header: t("soldProducts.amt"),
  }),
  columnHelper.accessor('SubTotal', {
    header: t("soldProducts.taxExcl"),
    cell: (info) => info.getValue().toFixed(2),
  }),
  columnHelper.accessor('TaxExcludedWithDiscount', {
    header: t("soldProducts.taxIncl"),
    cell: (info) => info.getValue().toFixed(2),
  }),
  columnHelper.accessor('Total', {
    header: t("soldProducts.taxInclInclBtw"),
    cell: (info) => info.getValue().toFixed(2),
  }),
];
