export const groupData = (data: any) => {
  const groupedData = data.reduce((acc: any, item: any) => {
    const mainCategory = item.MainCategory;
    const subCategory = item.SubCategory;

    if (!acc[mainCategory]) {
      acc[mainCategory] = {
        items: [],
        totalQuantity: 0,
        totalSubTotal: 0,
        totalTotal: 0,
        totalPercents: 0,
      };
    }

    if (!acc[mainCategory][subCategory]) {
      acc[mainCategory][subCategory] = {
        items: [],
        totalQuantity: 0,
        totalSubTotal: 0,
        totalTotal: 0,
        totalPercents: 0,
      };
    }

    acc[mainCategory].items.push(item);
    acc[mainCategory].totalQuantity += item.Quantity;
    acc[mainCategory].totalSubTotal += item.SubTotal;
    acc[mainCategory].totalTotal += item.Total;
    acc[mainCategory].totalPercents += item.Percents;

    acc[mainCategory][subCategory].items.push(item);
    acc[mainCategory][subCategory].totalQuantity += item.Quantity;
    acc[mainCategory][subCategory].totalSubTotal += item.SubTotal;
    acc[mainCategory][subCategory].totalTotal += item.Total;
    acc[mainCategory][subCategory].totalPercents += item.Percents;

    return acc;
  }, {});

  return groupedData;
};

export const transformGroupedDetails = (
  groupedDetails: any,
  grandTotalType: number,
  t: (key: string) => string
) => {
  const transformedData: any[] = [];
  let grandTotal = {
    totalQuantity: 0,
    totalSubTotal: 0,
    totalTotal: 0,
    totalPercents: 0,
  };

  Object.keys(groupedDetails).forEach((mainCategory) => {
    const mainCategoryData = groupedDetails[mainCategory];

    Object.keys(mainCategoryData).forEach((subCategory) => {
      if (subCategory !== "items" && !subCategory.startsWith("total")) {
        mainCategoryData[subCategory].items.forEach((item: any) => {
          transformedData.push({
            ...item,
            MainCategory: mainCategory,
            SubCategory: subCategory,
            isSubTotal: false,
            isMainTotal: false,
          });
        });

        // Add Subcategory total
        // transformedData.push({
        //   DisplayOrder: 0,
        //   IdStatus: 0,
        //   IsPromotion: false,
        //   MainCategory: mainCategory,
        //   Percents: mainCategoryData[subCategory].totalPercents.toFixed(2),
        //   ProductType: 0,
        //   Quantity: mainCategoryData[subCategory].totalQuantity.toFixed(2),
        //   SubCategory: subCategory,
        //   SubTotal: mainCategoryData[subCategory].totalSubTotal.toFixed(2),
        //   TaxName: null,
        //   Total: mainCategoryData[subCategory].totalTotal.toFixed(2),
        //   isSubTotal: true,
        //   isMainTotal: false,
        //   Year: null,
        //   Date: null,
        //   Month: null,
        //   MonthName: null,
        //   Time: null,
        // });
      }
    });

    // Filter products based on the grandTotalType
    // const filteredItems = mainCategoryData.items.filter(
    //   (item: any) => item.ProductType === grandTotalType
    // );
        const filteredItems = mainCategoryData.items;

    // Add MainCategory total
    transformedData.push({
      DisplayOrder: 0,
      IdStatus: 0,
      IsPromotion: false,
      MainCategory: mainCategory,
      Percents: mainCategoryData.totalPercents.toFixed(2),
      ProductType: 0,
      Quantity: mainCategoryData.totalQuantity,
      SubCategory: mainCategory,
      SubTotal: mainCategoryData.totalSubTotal.toFixed(2),
      TaxName: null,
      Total: mainCategoryData.totalTotal.toFixed(2),
      isSubTotal: false,
      isMainTotal: true,
      Year: null,
      Date: null,
      Month: null,
      MonthName: null,
      Time: null,
    });

    // Update grand total based on filteredItems
    grandTotal.totalQuantity += filteredItems.reduce(
      (total: number, item: any) => total + item.Quantity,
      0
    );
    grandTotal.totalSubTotal += filteredItems.reduce(
      (total: number, item: any) => total + item.SubTotal,
      0
    );
    grandTotal.totalTotal += filteredItems.reduce(
      (total: number, item: any) => total + item.Total,
      0
    );
    grandTotal.totalPercents += filteredItems.reduce(
      (total: number, item: any) => total + item.Percents,
      0
    );
  });

  // Add Grand Total row
  transformedData.push({
    DisplayOrder: 0,
    IdStatus: 0,
    IsPromotion: false,
    MainCategory: `${t("soldProducts.total")}`,
    Percents: grandTotal.totalPercents.toFixed(2),
    ProductType: 0,
    Quantity: grandTotal.totalQuantity,
    SubCategory: `${t("soldProducts.total")}`,
    SubTotal: grandTotal.totalSubTotal.toFixed(2),
    TaxName: null,
    Total: grandTotal.totalTotal.toFixed(2),
    isSubTotal: false,
    isMainTotal: false,
    isGrandTotal: true, // Mark this as grand total row
    Year: null,
    Date: null,
    Month: null,
    MonthName: null,
    Time: null,
  });

  return transformedData;
};
