import React from "react";
import { useTranslation } from "react-i18next";
import { FeedbackAnalysisLineProps } from "./FeedbackAnalysis.type";

const FeedbackAnalysisLine: React.FC<FeedbackAnalysisLineProps> = ({
  feedbackRating,
  sum,
  text,
}) => {
  const { t } = useTranslation();

  const fullStarsSvg = `
  <svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 0L6.02963 2.60796L9 3.24671L6.975 5.49727L7.28115 8.5L4.5 7.28296L1.71885 8.5L2.025 5.49727L0 3.24671L2.97037 2.60796L4.5 0Z" fill="#FFCB45"/>
  </svg>
`;
  const halfStarSvg = `
  <svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 0L6.02963 2.60796L9 3.24671L6.975 5.49727L7.28115 8.5L4.5 7.28296L1.71885 8.5L2.025 5.49727L0 3.24671L2.97037 2.60796L4.5 0Z" fill="#c2c2c2"/>
    <path d="M1.71885 8.5L4.5 7.28296V0L2.97037 2.60796L0 3.24671L2.025 5.49727L1.71885 8.5Z" fill="#FFCB45"/>
  </svg>
`;
  const emptyStarsSvg = `
  <svg width="20" height="20" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 0L6.02963 2.60796L9 3.24671L6.975 5.49727L7.28115 8.5L4.5 7.28296L1.71885 8.5L2.025 5.49727L0 3.24671L2.97037 2.60796L4.5 0Z" fill="#c2c2c2"/>
  </svg>
`;

  const integerRating = Math.floor(feedbackRating);
  const fractionalRating = feedbackRating - integerRating;

  // Calculate full, half, and empty stars based on the integer part of the rating
  const fullStars = integerRating;
  const halfStar = fractionalRating >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <span
          key={i}
          dangerouslySetInnerHTML={{ __html: fullStarsSvg }}
          className="px-2"
        />,
      );
    }
    if (halfStar === 1) {
      stars.push(
        <span
          key="half"
          dangerouslySetInnerHTML={{ __html: halfStarSvg }}
          className="px-2"
        />,
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <span
          key={`empty-${i}`}
          dangerouslySetInnerHTML={{ __html: emptyStarsSvg }}
          className="px-2"
        />,
      );
    }
    return stars;
  };

  return (
    <div className="flex items-center text-gray-400 justify-between mb-3">
      <div className="w-1/12">{text}</div>
      <div className="flex w-9/12 justify-between">{renderStars()}</div>
      <div className="w-1/12"> {sum && `(${sum})`}</div>
    </div>
  );
};

export default FeedbackAnalysisLine;
