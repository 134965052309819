const BurgerMenuIcon = () => {
  const svgIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 12H15M3 6H21M3 18H21" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
    
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default BurgerMenuIcon;
