const ToggleOff = () => {
    const svgIcon = `
    <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="32" rx="16" fill="#CED1D9"/>
    <circle cx="16" cy="16" r="14" fill="white"/>
    </svg>            
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default ToggleOff;
  