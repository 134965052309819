import { useEffect, useState } from "react";
import Select from "../common/select/Select";
import Wrapper from "../common/wrapper/Wrapper";
import "react-datepicker/dist/react-datepicker.css";
import useDateFormatter from "../../hooks/useDateFormatter";
import { API_ROUTES } from "../../config";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import Heading from "../common/heading/Heading";
import Text from "../common/text/Text";
import useDateDisabling from "../../hooks/useDateDesabling";
import CustomDatePicker from "../common/customDatePicker/customDatePicker";
import useUpdateAvailableDates from "../../hooks/useUpdateAvailableDates";
import { ReceiptReportFilterProps } from "./ReceiptReportFilters.type";
import useIsMobileDevice from "../../hooks/useIsMobileDevice";
import ExportFilterActions from "../common/reportAction/ExportFilterActions";

const ReceiptReportFilters: React.FC<ReceiptReportFilterProps> = ({
  sendDateToParent,
  sendMenuIdToParent, 
  exportType, 
  fileUrl, 
  fetchReportData,
  handleExportChange,
  hideCSV
}) => {
  const { t } = useTranslation();
  const { formatDateSP } = useDateFormatter();

  const UserMenus = localStorage.getItem("UserMenus");
  const { fetchData } = useAxios();
  const location = useLocation();
  const [allowedDatesFromPicker, setAllowedDatesFromPicker] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const token = localStorage.getItem("token");
  const [fromDate, setFromDate] = useState<any>(formatDateSP(new Date(new Date().setDate(new Date().getDate() - 1))));
  const menuId = localStorage.getItem("MI")
  const lsMenuId = localStorage.getItem("menuId");
  const parsedLsMenuId = lsMenuId && JSON.parse(lsMenuId);
  const option = parsedLsMenuId ? { value: parsedLsMenuId.IdMenu, label: parsedLsMenuId.MenuName } : null;
  const UserMenusParsed = UserMenus ? JSON.parse(UserMenus) : null;
  const options = UserMenusParsed.map((location: any) => {
    return { value: location.IdMenu, label: location.MenuName };
  });

  useEffect(() => {
    if (location.pathname === "/reports/receiptReport" && menuId) {
      const getData = async () => {
        const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
        const requestBody = {
          IdMenu: menuId,
          "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
          "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
        };


        try {
          const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: requestBody,
          });
          const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
          setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getData();
    }
  }, [menuId, location.pathname, currentMonth]);
  useEffect(() => {
    if (
       location.pathname === "/reports/receiptReport"
    ) {
      if (menuId) {
        
        const getData = async () => {
          const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
          const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
          const requestBody = {
            IdMenu: menuId,
            "TargetDate": formatDateSP(new Date(startOfMonth.getTime() - 10 * 24 * 60 * 60 * 1000)),
            "TargetDateTo": formatDateSP(new Date(endOfMonth.getTime() + 10 * 24 * 60 * 60 * 1000))
          };


          try {
            const apiResponse = await fetchData(API_ROUTES.getAvaliableDates, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              data: requestBody,
            });
            const targetDates = apiResponse.data.map((date: any) => new Date(date.TargetDate));
            setAllowedDatesFromPicker(targetDates.sort((a: any, b: any) => b.getTime() - a.getTime()));
          } catch (error) {

          } finally {
        
          }
        };

        getData();
      }
    }
  }, [menuId, location.pathname]);

  const handleSelectChange = (selectedOption: any) => {
    const selectedLocationData = UserMenusParsed.find(
      (location: any) => location.IdMenu === selectedOption
    );
    const selectedMenuArray = { IdMenu: selectedLocationData.IdMenu, MenuName: selectedLocationData.MenuName }
    localStorage.setItem("menuId", JSON.stringify(selectedMenuArray))
    localStorage.setItem("MI", JSON.stringify(selectedMenuArray.IdMenu))

    if(selectedLocationData) {
      localStorage.setItem("longitude", selectedLocationData.Longitude);
      localStorage.setItem("latitude", selectedLocationData.Latitude);
      localStorage.setItem("fullAddress", selectedLocationData.FullAddress)
    }  
    window.location.reload();
  };

  useEffect(() => {
    if (fromDate) {
      sendDateToParent(fromDate);
    }
  }, [fromDate]);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);


  const { disabledDate } = useDateDisabling({ allowedDatesFromPicker });

  const isMobile = useIsMobileDevice();

  const { updateAvailableDates } = useUpdateAvailableDates({ 
    menuId, 
    token, 
    locationPathname: location.pathname 
  });

  return (
    <div className="pt-12 md:pt-20 flex flex-col items-center justify-center md:pb-4 fixed left-0 md:left-[20%] right-0 bg-[#FFFFFF40] backdrop-blur-[35px] z-10" >
      <div className="w-[95%] mx-auto flex flex-col gap-4">

      <div className="flex flex-col md:flex-row w-full">
    <div className="w-full">
            <Heading
              align="left"
              children={t("receiptReport.title")}
              classes="pt-4 md:pt-2 min-w-full text-[22px] md:text-4xl"
              color=""
              size="h1"
              />
            <Text
              align=""
              children={t("receiptReport.subTitle")}
              classes="text-[15px] md:text-base font-thin"
              color="text-custom-textGraySec"
              size=""
              />
          </div>
    
      <div className="w-full md:hidden">
        <Select
          classes="flex justify-center rounded-lg border border-custom-bgBlue bg-white md:w-1/3 relative mt-4 py-[7px]"
          options={options}
          onChange={handleSelectChange}
          defaultValue={option && option}
          />
        </div>

          <div className="flex flex-col h-fit w-full md:w-8/12 mt-4">
            <p className="text-sm md:text-base mb-1">{t("receiptReport.date")}</p>
            <fieldset>
              {fromDate && 
              <CustomDatePicker
              dateValue={fromDate}
              onDateChange={setFromDate}
              disabledDate={disabledDate}
              handleMonthChange={(date: any) => {updateAvailableDates(date, setAllowedDatesFromPicker)}}
              placeholder={t("datePicker.placeholder")}
              allowedDates={allowedDatesFromPicker}
              mode={"date"}
              />
            }
             
            </fieldset>
          </div>
          </div>

          <ExportFilterActions
          fileUrl={fileUrl ? fileUrl : ""}
          exportType={exportType ? exportType : ""}
          handleExportChange={handleExportChange ? handleExportChange : () => {}}
          fetchReportData={fetchReportData ? fetchReportData : () => {}}
          hideCSV={true}
          />
                  
      </div>
    </div>
  );
};

export default ReceiptReportFilters;
