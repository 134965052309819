const BlackLogoWithoutTextIcon = () => {
    const svgIcon = `<svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_614_11700)">
    <path d="M17.7775 31.893C13.2302 31.8884 8.87056 30.2126 5.65497 27.2334C2.43938 24.2541 0.630441 20.2147 0.625 16.0012V0.10791H34.9364V16.0012C34.931 20.2157 33.1212 24.256 29.9042 27.2354C26.6873 30.2148 22.326 31.8899 17.7775 31.893ZM4.31545 3.52743V16.0012C4.31545 19.3094 5.73378 22.4822 8.25841 24.8215C10.783 27.1608 14.2072 28.475 17.7775 28.475C21.3479 28.475 24.7721 27.1608 27.2967 24.8215C29.8213 22.4822 31.2396 19.3094 31.2396 16.0012V3.52743H4.31545Z" fill="#101828"/>
    <path d="M17.7305 17.84C18.8264 17.84 19.7148 17.0168 19.7148 16.0013C19.7148 14.9858 18.8264 14.1626 17.7305 14.1626C16.6345 14.1626 15.7461 14.9858 15.7461 16.0013C15.7461 17.0168 16.6345 17.84 17.7305 17.84Z" fill="#101828"/>
    <path d="M17.6881 10.7792L7.87695 5.52979V16.0272L17.6881 10.7792Z" fill="#101828"/>
    <path d="M17.6875 10.7792L27.4987 5.52979V16.0272L17.6875 10.7792Z" fill="#101828"/>
    </g>
    <defs>
    <clipPath id="clip0_614_11700">
    <rect width="36" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
    
    
 
      `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default BlackLogoWithoutTextIcon;
  