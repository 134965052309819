const LogoIcon = () => {
  const svgIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_437_450)">
  <path d="M11.717 21C9.25693 20.9974 6.89835 20.0484 5.15872 18.3612C3.41908 16.6741 2.44044 14.3865 2.4375 12.0004V3H21V12.0004C20.9971 14.3871 20.018 16.6751 18.2776 18.3624C16.5372 20.0496 14.1777 20.9982 11.717 21ZM4.43404 4.93648V12.0004C4.43404 13.8739 5.20135 15.6706 6.56718 16.9954C7.93301 18.3201 9.78547 19.0643 11.717 19.0643C13.6486 19.0643 15.5011 18.3201 16.8669 16.9954C18.2327 15.6706 19 13.8739 19 12.0004V4.93648H4.43404Z" fill="#898989"/>
  <path d="M11.8477 13.0351C12.4641 13.0351 12.9639 12.572 12.9639 12.0008C12.9639 11.4296 12.4641 10.9666 11.8477 10.9666C11.2312 10.9666 10.7314 11.4296 10.7314 12.0008C10.7314 12.572 11.2312 13.0351 11.8477 13.0351Z" fill="#898989"/>
  <path d="M11.8243 9.06315L6.30551 6.11035V12.0151L11.8243 9.06315Z" fill="#898989"/>
  <path d="M11.8244 9.06315L17.3431 6.11035V12.0151L11.8244 9.06315Z" fill="#898989"/>
  </g>
  <defs>
  <clipPath id="clip0_437_450">
  <rect width="18.5625" height="18" fill="white" transform="translate(2.4375 3)"/>
  </clipPath>
  </defs>
  </svg>
  
  
  
  
    `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default LogoIcon;
