// src/utils/soldProductsUtils.ts

export const groupData = (data: any) => {
    const groupedData = data.reduce((acc: any, item: any) => {
      const mainCategory = item.MainCategory;
      const subCategory = item.SubCategory;
  
      if (!acc[mainCategory]) {
        acc[mainCategory] = {
          items: [],
          totalQuantity: 0,
          totalSubTotal: 0,
          totalTotal: 0,
          totalTaxExcludedWithDiscount: 0,
        };
      }
  
      if (!acc[mainCategory][subCategory]) {
        acc[mainCategory][subCategory] = {
          items: [],
          totalQuantity: 0,
          totalSubTotal: 0,
          totalTotal: 0,
          totalTaxExcludedWithDiscount: 0,
        };
      }
  
      acc[mainCategory].items.push(item);
      acc[mainCategory].totalQuantity += item.Quantity;
      acc[mainCategory].totalSubTotal += item.SubTotal;
      acc[mainCategory].totalTotal += item.Total;
      acc[mainCategory].totalTaxExcludedWithDiscount += item.TaxExcludedWithDiscount;
  
      acc[mainCategory][subCategory].items.push(item);
      acc[mainCategory][subCategory].totalQuantity += item.Quantity;
      acc[mainCategory][subCategory].totalSubTotal += item.SubTotal;
      acc[mainCategory][subCategory].totalTotal += item.Total;
      acc[mainCategory][subCategory].totalTaxExcludedWithDiscount += item.TaxExcludedWithDiscount;
  
      return acc;
    }, {});
  
    return groupedData;
  };
  
  export const transformGroupedDetails = (groupedDetails: any, grandTotalType: number, t: (key: string) => string) => {
    const transformedData: any[] = [];
    let grandTotal = {
      totalQuantity: 0,
      totalSubTotal: 0,
      totalTotal: 0,
      totalTaxExcludedWithDiscount: 0,
    };
  
    Object.keys(groupedDetails).forEach((mainCategory) => {
      const mainCategoryData = groupedDetails[mainCategory];
      
      Object.keys(mainCategoryData).forEach((subCategory) => {
        if (subCategory !== "items" && !subCategory.startsWith("total")) {
          mainCategoryData[subCategory].items.forEach((item: any) => {
            transformedData.push({
              ...item,
              MainCategory: mainCategory,
              SubCategory: subCategory,
              isSubTotal: false,
              isMainTotal: false,
            });
          });
  
          // Add Subcategory total
          transformedData.push({
            Year: null,
            Date: null,
            Month: null,
            MonthName: null,
            Time: null,
            ProductName: `${subCategory}`,
            Quantity: mainCategoryData[subCategory].totalQuantity,
            SubTotal: mainCategoryData[subCategory].totalSubTotal,
            TaxExcludedWithDiscount: mainCategoryData[subCategory].totalTaxExcludedWithDiscount,
            Total: mainCategoryData[subCategory].totalTotal,
            MainCategory: mainCategory,
            SubCategory: subCategory,
            isSubTotal: true,
            isMainTotal: false,
          });
        }
      });
  
      // Filter products based on the grandTotalType
      const filteredItems = mainCategoryData.items.filter((item: any) => item.ProductType === grandTotalType);
      
      // Add MainCategory total
      transformedData.push({
        Year: null,
        Date: null,
        Month: null,
        MonthName: null,
        Time: null,
        ProductName: `${mainCategory}`,
        Quantity: mainCategoryData.totalQuantity,
        SubTotal: mainCategoryData.totalSubTotal,
        TaxExcludedWithDiscount: mainCategoryData.totalTaxExcludedWithDiscount,
        Total: mainCategoryData.totalTotal,
        MainCategory: mainCategory,
        SubCategory: null,
        isSubTotal: false,
        isMainTotal: true,
      });
  
      // Update grand total based on filteredItems
      grandTotal.totalQuantity += filteredItems.reduce((total: number, item: any) => total + item.Quantity, 0);
      grandTotal.totalSubTotal += filteredItems.reduce((total: number, item: any) => total + item.SubTotal, 0);
      grandTotal.totalTotal += filteredItems.reduce((total: number, item: any) => total + item.Total, 0);
      grandTotal.totalTaxExcludedWithDiscount += filteredItems.reduce((total: number, item: any) => total + item.TaxExcludedWithDiscount, 0);
    });
  
    // Add Grand Total row
    transformedData.push({
      Year: null,
      Date: null,
      Month: null,
      MonthName: null,
      Time: null,
      ProductName: `${t("soldProducts.subTotal")}`,
      Quantity: grandTotal.totalQuantity,
      SubTotal: grandTotal.totalSubTotal,
      TaxExcludedWithDiscount: grandTotal.totalTaxExcludedWithDiscount,
      Total: grandTotal.totalTotal,
      MainCategory: null,
      SubCategory: null,
      isSubTotal: false,
      isMainTotal: false,
      isGrandTotal: true, // Mark this as grand total row
    });
  
    return transformedData;
  };
  