import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import nl from "./locales/nl.json"; // Import JSON files directly
import en from "./locales/en.json";

const loadLocales = async () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      fallbackLng: "en",
      returnObjects: true,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        nl: {
          translation: nl,
        },
        en: {
          translation: en,
        },
      },
    });
};

loadLocales();

export default i18n;
