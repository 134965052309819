const EyeOpenIcon = () => {
    const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M20.5215 11.2807C20.7516 11.5563 20.8789 11.9144 20.8789 12.2857C20.8789 12.6569 20.7516 13.015 20.5215 13.2907C19.0649 14.9857 15.7494 18.2856 11.8789 18.2856C8.00847 18.2856 4.69294 14.9857 3.23632 13.2907C3.00625 13.015 2.87891 12.6569 2.87891 12.2857C2.87891 11.9144 3.00625 11.5563 3.23632 11.2807C4.69294 9.58565 8.00847 6.28564 11.8789 6.28564C15.7494 6.28564 19.0649 9.58565 20.5215 11.2807Z" stroke="#898989" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.88 14.9522C13.4123 14.9522 14.6545 13.7583 14.6545 12.2855C14.6545 10.8128 13.4123 9.6189 11.88 9.6189C10.3477 9.6189 9.10547 10.8128 9.10547 12.2855C9.10547 13.7583 10.3477 14.9522 11.88 14.9522Z" stroke="#898989" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>    
      `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default EyeOpenIcon;
  