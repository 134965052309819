const ProfileIcon = () => {
  const svgIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C10.6739 4 9.40215 4.49752 8.46447 5.38311C7.52678 6.2687 7 7.46981 7 8.72222C7 9.97463 7.52678 11.1757 8.46447 12.0613C8.53043 12.1236 8.59804 12.184 8.66721 12.2424C7.81069 12.6131 7.02217 13.1272 6.34315 13.7685C4.84285 15.1855 4 17.1073 4 19.1111V20.0556C4 20.5772 4.44772 21 5 21C5.55228 21 6 20.5772 6 20.0556V19.1111C6 17.6082 6.63214 16.1669 7.75736 15.1042C8.88258 14.0415 10.4087 13.4444 12 13.4444C13.5913 13.4444 15.1174 14.0415 16.2426 15.1042C17.3679 16.1669 18 17.6082 18 19.1111V20.0556C18 20.5772 18.4477 21 19 21C19.5523 21 20 20.5772 20 20.0556V19.1111C20 17.1073 19.1571 15.1855 17.6569 13.7685C16.9778 13.1272 16.1893 12.6131 15.3328 12.2424C15.402 12.184 15.4696 12.1236 15.5355 12.0613C16.4732 11.1757 17 9.97463 17 8.72222C17 7.46981 16.4732 6.2687 15.5355 5.38311C14.5979 4.49752 13.3261 4 12 4ZM9.87868 6.71875C10.4413 6.1874 11.2044 5.88889 12 5.88889C12.7956 5.88889 13.5587 6.1874 14.1213 6.71875C14.6839 7.25011 15 7.97078 15 8.72222C15 9.47367 14.6839 10.1943 14.1213 10.7257C13.5587 11.257 12.7956 11.5556 12 11.5556C11.2044 11.5556 10.4413 11.257 9.87868 10.7257C9.31607 10.1943 9 9.47367 9 8.72222C9 7.97078 9.31607 7.25011 9.87868 6.71875Z" fill="#898989"/>
  </svg>  
    `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default ProfileIcon;
