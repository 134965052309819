import React from "react";
import { WrapperProps } from "./Wrapper.type";

const Wrapper: React.FC<WrapperProps> = ({
  children,
  classes = "",
  onClick,
}) => {
  return (
    <div className={classes} onClick={onClick}>
      {children}
    </div>
  );
};

export default Wrapper;
