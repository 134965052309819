import { useCallback } from "react";

const useTimeConverter = () => {
  const convertTimeToHour = useCallback((time: string): number => {
    const [hour] = time.split(":");
    return parseInt(hour, 10);
  }, []);

  return { convertTimeToHour };
};

export default useTimeConverter;
