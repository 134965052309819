import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import ZReportRow from "../zReportRow/ZReportRow";

interface Props {
    dailyTotals: any;
}


const DailyTotalsComponent: React.FC<Props> = ({dailyTotals}) => {
    const { t } = useTranslation();

  return (
    <Wrapper classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg" onClick={() => {}}>
    <ZReportRow title={t("zreport.turnover")} value={dailyTotals[0]?.Turnover} color="bg-custom-bgPink"/>
    <ZReportRow title={t("zreport.discount")} value={dailyTotals[0]?.Discount} color="bg-custom-bgPink"/>
    <ZReportRow title={t("zreport.total")} value={dailyTotals[0]?.Total} color="bg-custom-bgPink" classes="py-2" borderRadius="rounded-md"/>
  </Wrapper>
  );
};

export default DailyTotalsComponent;
