import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<any>()

export const getProductsTableHeaders = (t: any) => [
    columnHelper.accessor('LastModified', {
      header: t("periodReport.date")
    }),
    columnHelper.accessor('AvgPB', {
      header: t("periodReport.avgPB")
    }),
    columnHelper.accessor('AvgPP', {
      header: t("periodReport.avgPP")
    }),
    columnHelper.accessor('TotalOrders', {
      header: t("periodReport.total")
    }),
  ]