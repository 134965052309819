const StarIcon = () => {
  const svgIcon = `
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="3" width="33.513" height="33.513" rx="16.7565" fill="#8FC1CB" fill-opacity="0.3"/>
  <rect x="3" y="3" width="33.513" height="33.513" rx="16.7565" stroke="#F9F5FF" stroke-width="5.58549"/>
  <path d="M19.2556 13.7892C19.4165 13.4632 19.497 13.3002 19.6062 13.2481C19.7013 13.2028 19.8117 13.2028 19.9067 13.2481C20.0159 13.3002 20.0964 13.4632 20.2573 13.7892L21.784 16.8821C21.8315 16.9784 21.8553 17.0265 21.89 17.0639C21.9207 17.0969 21.9576 17.1238 21.9986 17.1428C22.0448 17.1643 22.0979 17.1721 22.2041 17.1876L25.6191 17.6867C25.9787 17.7393 26.1585 17.7656 26.2418 17.8534C26.3142 17.9298 26.3482 18.0349 26.3344 18.1392C26.3186 18.2592 26.1884 18.386 25.9281 18.6395L23.4579 21.0455C23.3809 21.1205 23.3424 21.158 23.3175 21.2026C23.2955 21.2421 23.2814 21.2855 23.276 21.3304C23.2698 21.3811 23.2789 21.4341 23.2971 21.5401L23.8799 24.9384C23.9414 25.2968 23.9721 25.476 23.9144 25.5824C23.8641 25.6749 23.7748 25.7398 23.6712 25.759C23.5522 25.7811 23.3913 25.6964 23.0694 25.5272L20.0164 23.9216C19.9213 23.8716 19.8738 23.8466 19.8237 23.8368C19.7793 23.8281 19.7337 23.8281 19.6893 23.8368C19.6392 23.8466 19.5916 23.8716 19.4965 23.9216L16.4435 25.5272C16.1216 25.6964 15.9607 25.7811 15.8417 25.759C15.7382 25.7398 15.6488 25.6749 15.5986 25.5824C15.5408 25.476 15.5715 25.2968 15.633 24.9384L16.2159 21.5401C16.234 21.4341 16.2431 21.3811 16.237 21.3304C16.2315 21.2855 16.2174 21.2421 16.1954 21.2026C16.1706 21.158 16.1321 21.1205 16.0551 21.0455L13.5849 18.6395C13.3245 18.386 13.1944 18.2592 13.1785 18.1392C13.1647 18.0349 13.1988 17.9298 13.2712 17.8534C13.3544 17.7656 13.5342 17.7393 13.8938 17.6867L17.3088 17.1876C17.415 17.1721 17.4681 17.1643 17.5144 17.1428C17.5553 17.1238 17.5922 17.0969 17.6229 17.0639C17.6576 17.0265 17.6814 16.9784 17.7289 16.8821L19.2556 13.7892Z" stroke="#0DB5D6" stroke-width="1.39637" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  
      `;

  return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
};

export default StarIcon;
