const ExclamationMarkIcon = () => {
    const svgIcon = `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM6.80007 3.19999C6.80007 2.75816 7.15824 2.39999 7.60007 2.39999H8.40007C8.8419 2.39999 9.20007 2.75816 9.20007 3.19999V7.99999C9.20007 8.44182 8.8419 8.79999 8.40007 8.79999H7.60007C7.15824 8.79999 6.80007 8.44182 6.80007 7.99999V3.19999ZM6.40004 12C6.40004 12.8836 7.11638 13.6 8.00004 13.6C8.8826 13.5974 9.5974 12.8826 9.60004 12C9.60004 11.1163 8.88369 10.4 8.00004 10.4C7.11638 10.4 6.40004 11.1163 6.40004 12Z" fill="#FF453A"/>
    </svg>
        `;
  
    return <div dangerouslySetInnerHTML={{ __html: svgIcon }} />;
  };
  
  export default ExclamationMarkIcon;
  