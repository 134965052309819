import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import ZReportRow from "../zReportRow/ZReportRow";
import { v4 as uuidv4 } from 'uuid';

interface Props {
    paymentData: any;
}


const PaymentComponent: React.FC<Props> = ({paymentData}) => {
    const { t } = useTranslation();
    let totalPaymentsAmount;
    {paymentData.length > 0 ? (totalPaymentsAmount = paymentData.reduce((sum: any, item: any) => sum + item.Ammount, 0)) : (totalPaymentsAmount = 0)}

  return (
    <Wrapper onClick={() => {}} classes="w-full p-4 bg-white rounded-lg flex flex-col gap-2 mb-4 shadow-lg h-fit">
    {paymentData.map((payment: any) => (
      payment.Ammount !== 0 && (
        <ZReportRow key={uuidv4()} title={payment.PaymentType} value={payment.Ammount.toFixed(2).toString()} color={`${payment.IdPaymentType === 13 ? "bg-custom-bgOrange" : ""}`}/>
      )
    ))}
    <ZReportRow title={t("zreport.total")} value={totalPaymentsAmount.toFixed(2)} color="bg-custom-bgPink" classes="mt-2" fontSize="text-base"/>
</Wrapper>
  );
};

export default PaymentComponent;
