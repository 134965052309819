import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type PaymentTypeId = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12" | "13" | "14" | "15" | "16" | "-100";

const usePaymentTypeMap = (): {
  map: Record<PaymentTypeId, string>;
  getPaymentTypeName: (id: PaymentTypeId | number) => string;
} => {
  const { t } = useTranslation();

  const map = useMemo(() => ({
    "1": t("paymentTypes.cash"),
    "2": t("paymentTypes.pin"),
    "3": t("paymentTypes.creditCard"),
    "4": t("paymentTypes.gift"),
    "5": t("paymentTypes.noPayment"),
    "6": t("paymentTypes.payNL"),
    "7": t("paymentTypes.onAccount"),
    "8": t("paymentTypes.cancel"),
    "9": t("paymentTypes.visa"),
    "10": t("paymentTypes.mastercard"),
    "11": t("paymentTypes.googlePay"),
    "12": t("paymentTypes.applePay"),
    "13": t("paymentTypes.oot"),
    "14": t("paymentTypes.dinnerGiftCard"),
    "15": t("paymentTypes.coinIntake"),
    "16": t("paymentTypes.own"),
    "-100": t("paymentTypes.combined"),
  }), [t]);

  const getPaymentTypeName = (id: PaymentTypeId | number) => map[id.toString() as PaymentTypeId] || t("paymentTypes.unknown");

  return { map, getPaymentTypeName };
};

export default usePaymentTypeMap;
