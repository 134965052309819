import { useTranslation } from "react-i18next";
import Wrapper from "../common/wrapper/Wrapper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, PointElement } from "chart.js";
import Heading from "../common/heading/Heading";
import { Doughnut } from "react-chartjs-2";
import { Props } from "./AvarageFoodSpendingChart.type";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, PointElement, ChartDataLabels);

const AvarageFoodSpendingChart: React.FC<Props> = ({ backendData }) => {
  const { t } = useTranslation();

  const labels = backendData.map((item) => item.CategoryName);
  const amounts = backendData.map((item) => item.Amount);

  const totalAmount = amounts.reduce((acc, cur) => acc + cur, 0);

  const percentages = amounts.map((amount) =>
    ((amount / totalAmount) * 100).toFixed(2),
  );
  const labelsWithPercentages = labels.map((label, index) => 
    `${label}: ${percentages[index]}%`
  );
  const data = {
    labels: labelsWithPercentages,
    datasets: [
      {
        label: "",
        data: amounts,
        backgroundColor: ["#B350FC", "#901EDC", "#DDB2FF"],
        pointStyle: 'circle' // Explicitly set point style
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide the built-in legend
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            const value = context.formattedValue;
            const percent = percentages[context.dataIndex];
            return `${label}${value} (${percent}%)`;
          },
        },
      },
      datalabels: {
        display: false,
        color: 'white',
        formatter: function (value: any, context: any) {
          const percent = percentages[context.dataIndex];
          return `${percent}%`;
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    radius: "100%",
    animation: {
      duration: 1000,
    },
  };
  
  return (
    <Wrapper
      onClick={() => {}}
      classes="w-full h-[335px] bg-white rounded-lg px-4 md:px-12 md:pt-7 min-w-full flex flex-col pb-0 shadow-custom1"
    >
      <Heading
        children={t("avarageFoodSpendingChart.title")}
        align=""
        classes="text-black text-left"
        color=""
        size="h1"
      />
      {!backendData || (Array.isArray(backendData) && backendData.length === 0) ? (
        <p className="pt-4 pb-4">{t("errorMessages.noDataAvailable")}</p>
      ) : (
        <div className="flex flex-row justify-between items-center h-full">
          <div className="w-[160px] h-[160px]">
            <Doughnut data={data} options={options}></Doughnut>
          </div>
          <div className="ml-4 flex flex-col justify-center">
            {labelsWithPercentages.map((label, index) => (
              <p key={index} className="text-sm text-gray-700 flex items-center mb-2">
                <span
                  className="min-w-[10px] min-h-[10px] rounded-full inline-block mr-2"
                  style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
                ></span>
                {label}
              </p>
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default AvarageFoodSpendingChart;
